// sass-lint:disable class-name-format
.datepicker-input-hidden {
    @include visuallyhidden;
}
.home-search {
    .asd__month-table {
        margin-bottom: 0;
    }
    .datepicker-wrapper {
        .asd__week {
            height: rem(44px);
        }
        .asd__wrapper {
            bottom: -16px;
            @include breakpoint(400px down) {
                max-width: calc(100% + 40px);
                left: 50%;
                transform: translate(-50%, 100%);
            }
        }
    }
}
.datepicker-wrapper {
    width: 100%;
    .datepicker-trigger {
        position: static;
    }
    .asd__wrapper {
        box-sizing: content-box;
        padding: 20px;
        border: 0;
        box-shadow: 0 10px 20px rgba(0, 0, 0, 0.19), 0 6px 6px rgba(0, 0, 0, 0.23);
        background: $color-ocean-green;
        bottom: 0;
        transform: translateY(100%);
        top: inherit !important;
    }
    .asd__change-month-button {
        top: 0;
        padding: 0;
        background-color: transparent;
        button {
            width: rem(44px);
            height: rem(40px);
            padding: rem(4px) 0;
            border: 0;
            background-color: transparent;
            &:hover {
                border: 0;
            }
            svg {
                fill: $color-white;
            }
        }
        &.asd__change-month-button--disabled {
            display: none;
        }
    }
    .asd__month {
        padding: 0;
        background: $color-ocean-green;
    }
    .asd__month-table {
        border: 0;
        background: $color-ocean-green;
        margin-bottom: 16px;
    }
    .asd__week {
        border: 0;
    }
    .asd__day--disabled {
        background: none !important;
        color: $color-white !important;
        opacity: 0.5;
        &:hover {
            .asd__day-button {
                background-color: transparent !important;
                color: $color-white !important;
            }
        }
        .asd__day-button {
            &:hover {
                color: $color-white !important;
                background-color: transparent !important;
                cursor: auto;
                border: 0 !important;
            }
        }
    }
    .asd__day--enabled {
        .asd__day-button {
            &:focus {
                outline: none;
            }
        }
        &:hover {
            background: none;
            .asd__day-button {
                background-color: $color-white;
                color: $color-black;
            }
        }
        &.asd__day--selected {
            border: 0 !important;
            background: none !important;
            .asd__day-button {
                background-color: $color-white;
                color: $color-black;
            }
        }
    }
    .asd__day {
        border: 0;
        width: rem(44px);
        height: rem(44px);
    }
    .asd__day--event {
        .asd__day-button {
            border: solid 1px $color-white;
            color: $color-white;
        }
    }
    .asd__day--futuredate {
        opacity: 1;
        .asd__day-button {
            color: $color-white;
        }
    }
    .asd__day-button {
        font-family: $f-display;
        letter-spacing: rem(1.8px);
        margin: 2px;
        width: calc(100% - 4px);
        height: calc(100% - 4px);
        @include rem-typo(12);
        @include breakpoint(medium) {
            @include rem-typo(18);
        }
        &:focus,
        &:active,
        &:hover {
            cursor: pointer;
            background: $color-white !important;
            border: solid 1px $color-white !important;
            color: $color-black;
        }
    }
    .asd__keyboard-shortcuts-trigger-wrapper {
        display: none;
    }
    .asd__month-name {
        display: flex;
        align-items: center;
        justify-content: center;
        height: rem(44px);
        margin: 0 0 rem(65px);
        @include rem-typo(18);
        background-color: $color-ocean-green;
        font-family: $f-display;
        text-transform: uppercase;
        color: $color-white;
        letter-spacing: rem(1.28px);
        @include breakpoint(medium) {
            @include rem-typo(22);
        }
        span {
            & + span {
                margin-left: 5px;
            }
        }
    }
    .asd__days-legend {
        z-index: 1;
        top: rem(44px);
        padding: 11px 0 10px;
        background-color: $color-black;
        margin-top: 20px;
    }
    .asd__day-title {
        @include rem-typo(12);
        font-family: $f-display;
        background: $color-black;
        color: $color-white;
        margin-bottom: 0;
        @include breakpoint(medium) {
            @include rem-typo(14);
        }
    }
    .is-aria-hidden {
        pointer-events: none;
    }
}
