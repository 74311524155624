.data-row {
    display: flex;
    justify-content: space-between;
    flex-flow: row wrap;
    border-top: solid 1px $color-grey-mineshaft;
    padding-top: 16px;
    padding-bottom: 16px;
    &:last-child {
        border-bottom: solid 1px $color-grey-mineshaft;
    }
    &.data-row--header {
        border-top: 0;
        padding-bottom: 9px;
        .data-label {
            font-weight: bold;
            text-transform: none;
        }
        .data-value {
            font-weight: normal;
        }
        @include breakpoint(medium) {
            padding-bottom: 18px;
        }
    }
    @include breakpoint(medium) {
        flex-flow: row nowrap;
        padding-top: 24px;
        padding-bottom: 24px;
    }
}
.data-label {
    @include rem-typo(14, 16);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding-right: 16px;
    @include breakpoint(medium) {
        @include rem-typo(18, 21);
    }
}
.data-value {
    @include rem-typo(14, 16);
    font-weight: bold;
    letter-spacing: 0.1em;
    margin-top: 8px;
    @include breakpoint(medium) {
        text-align: right;
        @include rem-typo(18, 21);
        margin-top: 0;
    }
}
