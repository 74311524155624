// ** Variables **

// -- Color definitions --
// Colors here should have a distinct name (avoid light, dark, lightest, etc.)
// Use http://chir.ag/projects/name-that-color/ if you don't have a name
// Should prioritize names from style documents
// Begins with $color-

$color-white: #fff;
$color-black: #201b18;
$color-true-black: #000;
$color-grey-mineshaft: #313131;
$color-grey-tundora: #434343;
$color-dusty-gray: #979797;
$color-cod-grey: #1a1a1a;
$color-alto: #d8d8d8;
$color-gallery: #ebebeb;
$color-torch-red: #ff1a20;
$color-pink: #e88aa4;

$color-mine-shaft: #3a3a3a;
$color-offwhite: #fffef5;
$color-white-rock: #e8e4cc;
$color-satin-linen: #eae8da;
$color-acapulco: #73b6a3;
$color-ocean-green: #469b84;
$color-masala: #3b3532;
$color-driftwood: #a58448;
$color-shadow: #867047;
$color-bandicoot: #868373;
$color-gray: #808080;
$color-cinnabar: #e3404b;
$color-cardinal: #ab192f;
$color-jacarta: #422462;
$color-mountains-majesty: #9475b6;
$color-onahau: #c5deff;
$color-seance: #6e2383;

// Festival Big Bang
$color-aqua-island: #a1d6db;
$color-jagged-ice: #b5ebec;
$color-denim: #0e6ab3;
$color-rhino: #283862;
$color-monza: #e20714;

// -- Color uses --
// Colors here should use a color above to be asigned to a use in an other module
// Begins with $c-

$c-body-bg: $color-offwhite;
$c-body-text: $color-masala;
$c-hr-border: $color-cod-grey;
$c-form-inputs-border: $color-cod-grey;
$c-form-alert: $color-torch-red;

// -- Fonts --
$font-butler: "Butler", serif;
$font-neue: "Neue Plak GTQ", sans-serif;
$f-default: "Barlow", sans-serif;
$f-display: $font-neue;
$f-titles: $font-butler;
