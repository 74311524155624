.video {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: $color-black;
    width: 100%;
    height: 100%;
    video {
        width: 100%;
        max-height: 100%;
    }
}
