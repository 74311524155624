// ** Footer **
.footer {
    padding: rem(45px) 0 rem(40px);
    background: $color-black;
    color: $color-white;
    @include breakpoint(large) {
        padding: rem(88px) rem(78px) rem(45px);
    }
    .action-btn {
        @include rem-typo(18, 18);
        display: flex;
        justify-content: space-between;
        font-weight: bold;
        letter-spacing: rem(1.5px);
        text-transform: uppercase;
        color: $color-white;
        border: 1px solid $color-ocean-green;
        padding: rem(15px) rem(22px);
        width: 100%;
        margin-bottom: 40px;
        svg {
            fill: $color-ocean-green;
        }
        &:hover {
            color: $color-ocean-green;
        }
    }
    &.has-sticky-bottom {
        padding-bottom: 75px;
    }
}
.footer-logo {
    display: block;
    margin-bottom: 18px;
    svg {
        fill: $color-white;
    }
    &:hover,
    &:focus {
        svg {
            fill: $color-white;
        }
    }
}
.footer-address {
    @include rem-typo(14, 18);
    letter-spacing: rem(0.47px);
    font-weight: 500;
    margin-bottom: 42px;
}
.footer-title {
    @include rem-typo(18, 21);
    letter-spacing: rem(1.13px);
    font-family: $f-titles;
    font-weight: bold;
    color: $color-white-rock;
    margin-bottom: 18px;
}
.footer-contact {
    margin-bottom: 24px;
    p {
        @include rem-typo(16, 18);
        font-weight: bold;
        letter-spacing: rem(0.54px);
    }
    a {
        &:first-child {
            font-weight: 400;
        }
    }
}
.footer-copyrights,
.footer-libeo {
    @include rem-typo(13, 16);
    font-weight: 500;
    letter-spacing: rem(0.43px);
    margin-top: 30px;
    @include breakpoint(large) {
        margin-top: 0;
    }
}
.footer-libeo {
    a {
        text-decoration: underline;
    }
}
.footer-action-link {
    display: flex;
    align-items: center;
    @include rem-typo(14, 16);
    font-weight: bold;
    color: $color-ocean-green;
    letter-spacing: rem(0.47px);
    .icon-wrapper {
        margin-left: 10px;
    }
    svg {
        fill: $color-ocean-green;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover {
        color: $color-white;
        svg {
            fill: $color-white;
            transform: translateX(35%);
        }
    }
}
.footer-mail-link {
    color: $color-white;
    text-decoration: underline;
    &:hover {
        color: $color-ocean-green;
        text-decoration: none;
    }
}
