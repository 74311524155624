.programmation-list {
    .page-header {
        margin-bottom: 0;
        padding: rem(16px) 0;
        @include breakpoint(medium) {
            padding-top: rem(60px);
            padding-bottom: rem(16px);
        }
    }
    .page-title {
        @include rem-typo(34px);
        margin-bottom: 0;
        @include breakpoint(medium) {
            @include rem-typo(64px);
        }
    }
    .events-header-background {
        background-color: $color-black;
        color: $color-white;
    }
}
.events-header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-bottom: rem(32px);
    @include breakpoint(medium) {
        padding-bottom: rem(80px);
    }
}
.events-content {
    @include breakpoint(medium) {
        margin-left: rem(-12px);
        margin-right: rem(-12px);
    }
}

.event-date-list,
.event-date-no-shows {
    padding: rem(30px) 0 rem(44px);
    @include breakpoint(501px) {
        width: 100%;
    }
    @include breakpoint(medium) {
        flex-grow: 1;
        padding: rem(30px) rem(20px) rem(44px);
    }
    @include breakpoint(large) {
        padding: rem(88px) rem(20px) rem(88px) rem(44px);
    }
}
.event-date-list {
    flex-grow: 1;
    width: 100%;
}
.event-no-shows {
    margin-bottom: rem(44px);
    margin-top: rem(44px);
    &.event-date-no-shows {
        hr {
            margin: 44px 0;
            @include breakpoint(medium) {
                margin: 88px 0;
            }
        }

        @include breakpoint(medium) {
            margin-top: 0;
        }
    }
    &.event-list-no-shows {
        hr {
            margin: 20px 0;
        }
    }

    .no-shows-header {
        font-family: $f-display;
        text-transform: uppercase;
        @include rem-typo(18px);
        margin-bottom: 8px;
        @include breakpoint(medium) {
            @include rem-typo(24px);
        }
    }
    .no-shows-advice {
        font-family: $f-titles;
        margin: 0;
    }
    .next-date-btn {
        display: flex;
        align-items: center;
        flex-flow: row nowrap;
        font-family: $f-display;
        letter-spacing: rem(2px);
        text-transform: uppercase;
        margin-left: auto;
        cursor: pointer;
        .long-arrow-next-wrapper {
            margin-left: 16px;
        }
    }
}
.search-indicator {
    color: $color-white;
    padding-bottom: 30px;
    @include breakpoint(medium) {
        margin-top: -55px;
    }
}
.event-grid {
    align-items: center;
    @include breakpoint(large) {
        align-items: flex-start;
    }
}
.event-grid-list-background {
    margin-top: -1px;
    background-image: linear-gradient(to bottom, #201b18 300px, #fffef5 300px);
    @include breakpoint(large) {
        background-image: linear-gradient(to bottom, #201b18 350px, #fffef5 350px);
    }
}
