.serie-header {
    background-repeat: no-repeat;
    background-size: cover;
    background-position-x: center;
    border-left: 6px solid var(--el-color);
    position: relative;
    overflow: hidden;
    color: $color-white;
    padding: 48px 0 84px;
    @include breakpoint(large) {
        border-width: 12px;
        padding: 64px 0 372px;
    }
    .serie-header-text {
        position: relative;
        z-index: 1;
        padding: 0 8px;
        @include breakpoint(medium) {
            max-width: calc(100% * (2/3));
            padding: 0;
        }
    }
    .serie-title {
        font-family: $font-butler;
        margin-bottom: 16px;
        @include rem-typo(48px, 48px);
        @include breakpoint(large) {
            margin-bottom: 32px;
            @include rem-typo(72px, 72px);
        }
    }
    .serie-description {
        @include breakpoint(large) {
            @include rem-typo(22px, 36px);
        }
    }
    .icon-gtq-shape-serie-wrapper {
        position: absolute;
        right: -40px;
        top: 0;
        @include breakpoint(large) {
            right: -116px;
            top: -180px;
        }
        svg {
            fill: var(--el-color);
            @include breakpoint(large) {
                height: 1380px;
                width: 656px;
            }
        }
    }
    .grid-container {
        position: relative;
    }
    .serie-badge {
        position: absolute;
        right: -36px;
        bottom: -124px;
        display: none;
        z-index: 1;
        @include breakpoint(large) {
            display: block;
        }
    }
}
.serie-events-list {
    position: relative;
    z-index: 1;
    margin-top: -50px;
    @include breakpoint(large) {
        margin-top: -295px;
    }
}
