.bounce-enter-active {
    animation: bounce-in .5s;
}
.bounce-leave-active {
    animation: bounce-in .5s reverse;
}
@keyframes bounce-in {
    0% {
        transform: scale(0);
    }
    50% {
        transform: scale(1.5);
    }
    100% {
        transform: scale(1);
    }
}
@keyframes flipdown {
    0% {
        opacity: 0;
        transform-origin: top center;
        transform: rotateX(-90deg);
    }
    5% {
        opacity: 1;
    }
    80% {
        transform: rotateX(8deg);
    }
    83% {
        transform: rotateX(6deg);
    }
    92% {
        transform: rotateX(-3deg);
    }
    100% {
        transform-origin: top center;
        transform: rotateX(0deg);
    }
}
