.home-showcase {
    margin-top: -1px;
    padding-bottom: 90px;
    background-image: url("../img/gtq-bg-shape-mobile.svg");
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @include breakpoint(medium) {
        padding-bottom: 120px;
        background-image: url("../img/gtq-bg-shape.svg");
    }
    .section-header {
        margin-bottom: 24px;
        @include breakpoint(medium) {
            margin-bottom: 32px;
        }
    }
    .medium-4 {
        img {
            aspect-ratio: 4 / 5;
        }
    }
    .medium-6 {
        &:first-child {
            .showcase-item {
                @include breakpoint(medium) {
                    margin-top: 80px;
                }
            }
        }
        img {
            aspect-ratio: 9 / 8;
        }
    }
    .large-8 {
        margin: auto;
        .showcase-item {
            max-width: none;
        }
        img {
            aspect-ratio: 19 / 12;
        }
    }
}
.showcase-item {
    display: block;
    position: relative;
    margin: auto;
    margin-bottom: 60px;
    max-width: 300px;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;

    }
    @include breakpoint(medium) {
        max-width: none;
        margin-bottom: 0;
    }
    &:hover {
        .showcase-item-title {
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transform: scale(1.1);
            color: $color-white;
        }
    }
}
.showcase-item-title {
    @include rem-typo(16, 16);
    position: absolute;
    bottom: -26px;
    left: 10%;
    right: 10%;
    background: $color-ocean-green;
    color: $color-white;
    letter-spacing: 0.1em;
    padding: 24px;
    font-family: $f-display;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
