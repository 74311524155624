// ** Base / Reset **

// Prevent size horizontal scroll
html,
body {
    overflow-x: hidden;
    position: relative;
}

// Reset box-sizing to border-box
html {
    box-sizing: border-box;
}

// Keep box-sizing inheritance
*,
*::before,
*::after {
    box-sizing: inherit;
}

// Keep content-box for images to prevent image resizing
img {
    box-sizing: content-box;
}

// Body styles
body {
    display: flex;
    flex-direction: column;
    min-height: 100vh;
    color: $c-body-text;
    background-color: $c-body-bg;
    font-family: $f-default;
}

main {
    flex-grow: 1;
}

h1,
h2,
h3,
h4,
h5,
h6 {
    font-weight: 700;
}

a {
    color: inherit;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    svg {
        fill: inherit;
    }
    &:hover,
    &:focus {
        color: $color-ocean-green;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        svg {
            fill: $color-ocean-green;
        }
    }
}

*,
*:focus {
    outline: none;
}

// sass-lint:disable force-pseudo-nesting
[data-state="keydown"] {
    *:focus,
    [type="radio"]:focus + label,
    [type="checkbox"]:focus + label {
        box-shadow: 0 0 0 1px $color-white, 0 0 0 3px $color-black, 0 0 0 4px $color-white !important;
    }
}

// Responsive images, svg and iframes
img,
svg,
iframe {
    max-width: 100%;
}

// Remove the gap between elements and the bottom of their containers
audio,
canvas,
iframe,
img,
svg,
video {
    vertical-align: middle;
}

// Remove default fieldset styles
fieldset {
    margin: 0;
    padding: 0;
    border: 0;
}

// Allow only vertical resizing of textareas
textarea {
    resize: vertical;
}

// Default horizontal rule
hr {
    margin: 2em 0;
    border-top: 0;
    border-bottom: 1px solid $c-hr-border;
}

// Default list style
ul,
ol {
    padding: 5px 0;
    li > &:first-child {
        margin-top: 10px;
    }
}

// Disable default list style inside navigation
nav ul {
    margin: 0;
    padding: 0;
    font-size: 0;
    list-style-type: none;
    li {
        display: inline-block;
        margin: 0;
        padding: 0;
        font-size: 1rem;
    }
}

// Remove default underline for links inside navigation
nav a {
    text-decoration: none;
}

// Reset default font-size for code, kbd, pre, samp elements
code,
kbd,
pre,
samp {
    font-size: 1rem;
}
