.card {
    color: $color-white;
    margin-bottom: rem(44px);
    svg {
        fill: $color-white;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover {
        color: $color-ocean-green;
        .card-title {
            color: $color-ocean-green;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        svg {
            fill: $color-ocean-green;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            transform: translateX(35%);
        }
        .card-content {
            color: $color-white;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
}
.card-image {
    position: relative;
    img {
        width: 100%;
    }
    .card-spotlight & {
        margin-left: -20px;
        margin-right: -20px;
        @include breakpoint(medium) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
.card-info {
    flex-direction: column;
    position: relative;
    display: flex;
    justify-content: space-between;
    @include breakpoint(medium) {
        flex-direction: row;
    }
    .card-spotlight & {
        flex-flow: row nowrap;
        background-color: $color-black;
        margin-top: rem(-44px);
        @include breakpoint(medium) {
            margin-left: 24px;
            margin-right: 24px;
        }
        @include breakpoint(large) {
            margin-left: 40px;
            margin-right: 40px;
        }
    }
}
.card-header {
    color: $color-white;
    display: flex;
    flex-grow: 1;
    justify-content: space-between;
    align-items: center;
    margin-left: 8px;
    .icon-wrapper {
        flex-shrink: 0;
    }
    .card-spotlight & {
        flex-flow: row wrap;
        padding: rem(24px);
        margin-left: 0;
        .icon-wrapper {
            width: 24px;
            @include breakpoint(large) {
                width: auto;
            }
        }
        @include breakpoint(large) {
            padding: rem(24px) rem(35px);
        }
    }
}
.card-title {
    @include rem-typo(21);
    font-family: $f-display;
    font-weight: 800;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    .card-spotlight & {
        width: 100%;
        @include breakpoint(large) {
            width: auto;
        }
    }
    @include breakpoint(1200px) {
        @include rem-typo(26, 30);
    }
}
.card-content {
    @include rem-typo(20, 24);
    font-weight: bold;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: right;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    .card-spotlight & {
        background: $color-ocean-green;
        background: linear-gradient(84deg, transparent 10%, $color-ocean-green calc(10% + 1px));
        flex-direction: column;
        padding: 28px 30px 28px 40px;
        span {
            margin-left: 0;
        }
    }
}
.listing-container {
    .card {
        margin-bottom: rem(68px);
    }
    .card-info {
        padding: rem(22px);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $color-black;
    }
    .card-header {
        margin-bottom: 12px;
        @include breakpoint(medium) {
            margin-bottom: 24px;
        }
    }
    .card-title {
        @include rem-typo(22, 28);
        font-family: $f-display;
        font-weight: 800;
        text-transform: uppercase;
        text-align: center;
    }
    .card-content {
        @include rem-typo(16, 19);
        font-weight: 500;
        margin-bottom: 24px;
        @include breakpoint(medium) {
            margin-bottom: rem(42px);
        }
    }
}
