// sass-lint:disable no-vendor-prefixes
.home-search-wrapper {
    padding: rem(16px) 0;
    @include breakpoint(830px) {
        padding: rem(48px) 0;
    }
    .home-search {
        flex-wrap: wrap;
    }
    .home-search-item {
        background: $color-masala;
        flex-shrink: 0;
        width: 100%;
        // Change Autocomplete styles in Chrome
        input,
        textarea,
        select {
            &:-webkit-autofill {
                -webkit-text-fill-color: $color-white;
                box-shadow: 0 0 0 1000px $color-masala inset;
                transition: background-color 5000s ease-in-out 0s;
                &:hover,
                &:focus {
                    -webkit-text-fill-color: $color-white;
                    box-shadow: 0 0 0 1000px $color-masala inset;
                    transition: background-color 5000s ease-in-out 0s;
                }
            }
        }
        @include breakpoint(830px) {
            width: 230px;
        }
        @include breakpoint(large) {
            width: 310px;
        }
        &:first-child {
            width: auto;
            flex: 1 1 auto;
        }
        &:last-child {
            .home-search-form {
                border: 0;
            }
        }
    }
    .home-search-select {
        position: relative;
        .event-filter-inner {
            margin-bottom: 0;
            margin-top: rem(8px);
        }
        .event-filter-placeholder {
            padding-left: rem(10px);
        }
        .active-event-filter {
            padding-left: rem(10px);
            padding-right: rem(45px);
            color: $color-white;
        }
        .filter-icon-wrapper {
            margin-top: rem(5px);
        }
    }
}
.home-search-form {
    position: relative;
    display: flex;
    align-items: center;
    margin: 0 rem(16px);
    padding: rem(16px) 0;
    border-bottom: 1px solid transparentize($color-white, 0.8);
    @include breakpoint(830px) {
        margin: rem(20px);
        margin-right: 0;
        padding: 0;
        padding-right: rem(20px);
        border: 0;
        border-right: 1px solid transparentize($color-white, 0.8);
    }
    @include breakpoint(large) {
        margin: rem(20px) 0 rem(20px) rem(30px);
        padding-right: rem(30px);
    }
    .home-search-input,
    .home-search-date {
        @include rem-typo(16, 19);
        letter-spacing: rem(1.24px);
        border: 0;
        background: none;
        box-shadow: none;
        color: $color-white;
        margin-bottom: 0;
        padding: 0 0 0 rem(40px);
        &::placeholder {
            color: $color-white;
        }
    }
    .home-search-input {
        @include rem-typo(14);
        @include breakpoint(medium) {
            @include rem-typo(16, 19);
        }
    }
    .home-search-date {
        @include rem-typo(14, 20);
        font-family: $f-display;
        font-weight: 800;
        letter-spacing: rem(1.4px);
        text-transform: uppercase;
        option {
            appearance: none;
            background: $color-white;
            color: $color-black;
            text-transform: none;
            font-family: Arial, sans-serif;
            font-weight: normal;
        }
    }
    .search-icon-wrapper,
    .filter-icon-wrapper,
    .calendar-icon-wrapper {
        position: absolute;
        left: 0;
        top: 50%;
        display: flex;
        transform: translateY(-50%);
        pointer-events: none;
    }
    svg {
        fill: $color-white;
    }
}
.home-categories-vue-container {
    width: 100%;
}
.home-search-form-submit {
    padding-top: rem(10px);
    cursor: pointer;
    svg {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        fill: $color-white;
    }
    &:focus,
    &:hover {
        outline: none;
        svg {
            transform: translateX(35%);
            fill: $color-ocean-green;
        }
    }
}
