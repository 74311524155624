// Slider Organismes résidents
.org-slider {
    margin-bottom: 20px;
    opacity: 0;
    .slick-track {
        display: flex;
        align-items: center;
    }
    @include breakpoint(medium) {
        margin-bottom: 48px;
    }
    &.slick-initialized {
        opacity: 1;
    }
}
.org-slide.slick-slide {
    display: flex;
    margin: 5px 4px;
    img {
        width: 100%;
        height: auto;
    }
}
.org-titles-slider-prev,
.org-titles-slider-next {
    position: relative;
    cursor: pointer;
    display: block;
    width: 29px;
    z-index: 999;
    margin-top: -48px;
    svg {
        fill: $color-ocean-green;
    }
    @include breakpoint(large) {
        margin-top: 0;
        width: auto;
    }
}
.org-titles-slider-next {
    margin-left: auto;
}
.org-titles-slider {
    position: relative;
    .slick-track {
        display: flex;
        align-items: center;
    }
    &::before {
        content: "";
        position: absolute;
        left: 50%;
        transform: translateX(-50%);
        width: 115%;
        height: 65%;
        background-image: linear-gradient(82deg, $color-black 5%, $color-masala calc(5% + 1px));
        @include breakpoint(400px) {
            left: 48%;
            height: 70%;
        }
        @include breakpoint(medium) {
            left: 50%;
        }
        @include breakpoint(large) {
            left: 42%;
            height: 150%;
            transform: translate(-50%, -50%);
            top: 50%;
            width: 100%;
        }
    }
}
.org-titles-slide {
    display: flex;
    align-items: center;
    margin: 5px;
    opacity: 0;
    .slick-initialized & {
        opacity: 1;
    }
}
.org-titles-slide-title {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: row wrap;
    text-align: center;
    span {
        @include rem-typo(20, 30);
        font-family: $f-display;
        flex-grow: 1;
        color: $color-white;
        margin-bottom: 20px;
        text-transform: uppercase;
        text-align: center;
        width: 100%;
        @include breakpoint(medium) {
            @include rem-typo(40, 48);
        }
        @include breakpoint(large) {
            @include rem-typo(40, 48);
            margin-bottom: 0;
        }
    }
    @include breakpoint(large) {
        flex-flow: row nowrap;
        text-align: left;
    }
}
.org-titles-slide-btn {
    @include rem-typo(14, 20);
    // flex-shrink: 0;
    max-width: 178px;
    padding: 10px;
    background: $color-ocean-green;
    color: $color-white;
    &:hover,
    &:focus,
    &:active {
        transform: scale(1.1);
        transition: all 300ms;
        color: $color-white;
    }
    @include breakpoint(large) {
        padding: 16px;
        max-width: none;
        margin-top: 0;
        margin-left: 28px;
        flex-shrink: 0;
        position: relative;
        right: rem(10px);
    }
}
