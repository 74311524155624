.block-link {
    a {
        display: inline-block;
        @include rem-typo(16, 20);
        letter-spacing: rem(1.5px);
        font-family: $f-display;
        font-weight: 800;
        color: $color-white;
        text-transform: uppercase;
        margin-top: 40px;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        .icon-wrapper {
            display: inline-block;
            vertical-align: top;
            @include breakpoint(medium) {
                margin-left: 16px;
            }
        }
        svg {
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            fill: $color-white;
            height: 10px;
            @include breakpoint(medium) {
                height: auto;
            }
        }
        &:hover {
            color: $color-ocean-green;
            svg {
                transform: translateX(35%);
                fill: $color-ocean-green;
            }
        }
        @include breakpoint(medium) {
            @include rem-typo(18, 26);
            letter-spacing: rem(1.69px);
        }
    }
}
