.logo-container {
    max-width: 224px;
    @include breakpoint(medium) {
        max-width: fit-content;
    }
}
.logo {
    display: flex;
    align-items: center;
    margin-top: 19px;
    margin-bottom: 16px;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    @include breakpoint(medium) {
        margin-top: 45px;
        margin-bottom: 45px;
    }
    svg {
        fill: $color-white;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        @include breakpoint(medium) {
            width: rem(280px);
            height: rem(61px);
        }
    }
    .logo-gtq-wrapper {
        max-width: 90px;
        @include breakpoint(medium) {
            max-width: none;
        }
    }
    .gtq-telus-header-wrapper {
        max-width: 90px;
        @include breakpoint(medium) {
            max-width: none;
        }
    }
    .telus-wrapper {
        max-width: 64px;
        @include breakpoint(medium) {
            max-width: none;
        }
    }
    &:hover,
    &:focus {
        transition: none;
        .logo-telus {
            transition: none;
            svg {
                fill: $color-white;
            }
        }
        svg {
            transition: none;
            fill: $color-white;
        }
    }
}
.homepage .logo {
    .icon-wrapper svg {
        fill: $color-black;
    }
    .logo-telus {
        border-left: solid 1px $color-black;
    }
}
.logo-telus {
    display: flex;
    align-items: center;
    margin-left: 15px;
    padding-left: 12px;
    border-left: solid 1px $color-white;
    max-width: 100px;
    svg {
        fill: $color-white;
    }
    @include breakpoint(medium) {
        max-width: none;
        margin-left: 24px;
        padding-left: 24px;
    }
}
