
.ie11 {
    .block-text-with-aside {
        .side-content {
            background: $color-masala;
            @include breakpoint(medium) {
                padding: 44px 30px 70px 55px;
                background: $color-masala;
            }
            @include breakpoint(large) {
                padding: 70px 30px 70px 55px;
                background: $color-masala;
            }
        }
    }

}
.block-text-with-aside {
    position: relative;
    min-height: 400px;
    .side-content {
        background-image: linear-gradient($color-masala);
        color: $color-white;
        margin-top: rem(64px);
        padding: rem(64px) rem(12px);
        @include breakpoint(medium) {
            background-image: linear-gradient(82deg, transparent 25%, $color-masala calc(25% + 1px));
            padding: rem(64px) 5% rem(64px) 14%;
            margin-top: 0;
            position: absolute;
            right: 0;
        }
    }
    .aside-details {
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include rem-typo(14, 20);
            font-family: $f-display;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: rem(1px);
            color: $color-white;
        }
        dl {
            margin-bottom: 0;
            & + dl {
                margin-top: 44px;
            }
        }
        dt {
            @include rem-typo(14, 20);
            font-family: $f-display;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: rem(1px);
            color: $color-white;
        }
        dd {
            @include rem-typo(18, 22);
            font-family: $f-default;
            font-weight: 500;
            letter-spacing: rem(0.5px);
            color: $color-white;
        }
        hr {
            border-bottom: 1px solid $color-white;
        }
    }
}
