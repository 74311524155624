// ** Header **
.header {
    background: $color-black;
    .skip-link {
        @include visuallyhidden;
        z-index: 100;
        position: fixed;
        top: 6px;
        left: 6px;
        background-color: $color-white;
        font-weight: 700;
        text-transform: uppercase;
        [data-state="keydown"] &:focus {
            @include visuallyhidden(false);
            position: fixed;
            padding: 8px;
            box-shadow: 0 0 0 1px $color-white, 0 0 0 3px $color-black, 0 0 0 4px $color-white;
        }
    }
    .grid-x {
        position: relative;
    }
    .homepage & {
        background-image: linear-gradient(82deg, $color-offwhite 260px, $color-black calc(260px + 1px));
        @include breakpoint(medium) {
            background-image: linear-gradient(82deg, $color-offwhite 420px, $color-black calc(420px + 1px));
        }
        @include breakpoint(1180px) {
            background-image: linear-gradient(82deg, $color-offwhite 35%, $color-black calc(35% + 1px));
        }
        @include breakpoint(1400px) {
            background-image: linear-gradient(82deg, $color-offwhite 38%, $color-black calc(38% + 1px));
        }
    }

    .message-banner {
        background-color: $color-cinnabar;
        color: $color-white;
        position: relative;
        display: none;

        &.show {
            display: block;
        }

        p {
            margin: 0  rem(30px) 0 0;

            @include breakpoint(medium) {
                margin: 0;
            }
        }

        a {
            color: $color-white;
            text-decoration: underline;
        }

        .banner-close {
            width: 18px;
            height: 18px;
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);

            .icon-close-icon {
                width: 100%;
                height: auto;
                align-self: flex-start;
                stroke: $color-white;
                padding-bottom: rem(12px);
                cursor: pointer;
            }
        }
    }
}
.ie11 .homepage .header {
    background-image: linear-gradient(82deg, $color-offwhite 36%, $color-black 36%);
}
