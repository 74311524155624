.secondary-nav {
    position: absolute;
    top: rem(20px);
    right: 0;
    justify-content: flex-end;
    display: flex;
}
.secondary-nav-items {
    display: flex;
}
.secondary-nav-item {
    position: relative;
    margin-left: rem(35px);
    margin-right: rem(35px);
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
        .submenu {
            left: auto;
            right: 0;
        }
    }
    &.has-submenu {
        .secondary-nav-link {
            padding-right: rem(20px);
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                margin-top: rem(-3px);
                right: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $color-white-rock;
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            &:hover,
            &.active,
            &.ancestor {
                &::after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $color-ocean-green;
                    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
            }
        }
        &.is-open {
            .submenu {
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                max-height: 800px;
                opacity: 1;
                transform: translate(0, 0);
            }
            .secondary-nav-link {
                &::after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid $color-white-rock;
                    border-top: 0;
                }
                &:hover,
                &.active,
                &.ancestor {
                    &::after {
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid $color-ocean-green;
                        border-top: 0;
                    }
                }
            }
        }
    }
    .submenu {
        display: none;
        z-index: 2;
        position: absolute;
        top: 100%;
        left: 0;
        padding: rem(8px) rem(20px) rem(16px);
        background: $color-masala;
        width: rem(200px);
        max-height: 0;
        opacity: 0;
        transform: translate(0, 50%);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        li {
            display: block;
        }
        a {
            display: inline-block;
            padding: rem(8px) 0;
            color: $color-white-rock;
            @include rem-typo(13, 16);
            font-family: $f-display;
            font-weight: 700;
            letter-spacing: rem(1.4px);
            text-transform: uppercase;
            &:hover,
            &.active {
                color: $color-ocean-green;
            }
        }
    }
}
.secondary-nav-link {
    position: relative;
    display: inline-block;
    @include rem-typo(14, 16);
    font-family: $f-display;
    font-weight: 700;
    letter-spacing: rem(1.4px);
    color: $color-white-rock;
    text-align: left;
    text-transform: uppercase;
    padding: rem(8px) 0;
    &:hover,
    &.active,
    &.ancestor {
        color: $color-ocean-green;
    }
}
