// sass-lint:disable indentation
@font-face {
    font-family: "Butler";
    src: url("../fonts/Butler-Medium.woff2") format("woff2"),
        url("../fonts/Butler-Medium.woff") format("woff");
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Butler";
    src: url("../fonts/Butler-Light.woff2") format("woff2"),
        url("../fonts/Butler-Light.woff") format("woff");
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Butler";
    src: url("../fonts/Butler-Black.woff2") format("woff2"),
        url("../fonts/Butler-Black.woff") format("woff");
    font-weight: 900;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Butler";
    src: url("../fonts/Butler-Bold.woff2") format("woff2"),
        url("../fonts/Butler-Bold.woff") format("woff");
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Butler";
    src: url("../fonts/Butler-ExtraBold.woff2") format("woff2"),
        url("../fonts/Butler-ExtraBold.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Butler";
    src: url("../fonts/Butler.woff2") format("woff2"),
        url("../fonts/Butler.woff") format("woff");
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: "Neue Plak GTQ";
    src: url("../fonts/gtq_neue_plak_narrow_black-webfont.woff2") format("woff2"),
    url("../fonts/gtq_neue_plak_narrow_black-webfont.woff") format("woff");
    font-weight: 800;
    font-style: normal;
    font-display: swap;
}
