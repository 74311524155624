.four-slides-slider-wrapper,
.series-carrousel-wrapper {
    position: relative;
    .event-card-inner,
    // sass-lint:disable force-pseudo-nesting
    .event-card-infos:hover {
        color: $color-white;
    }
    .event-card-infos {
        padding-bottom: 0;
    }
}
.four-slides-slider,
.series-carrousel-slider {
    margin: 0 -12px;
    width: calc(100% + 24px);
    .slick-track {
        display: flex !important;
    }
    .slick-slide {
        height: inherit !important;
    }
}
.four-slides-slider-prev,
.four-slides-slider-next,
.series-carrousel-slider-prev,
.series-carrousel-slider-next {
    display: flex;
    position: absolute;
    top: 214px;
    margin-top: -20px;
    cursor: pointer;
    background: $color-black;
    width: 40px;
    height: 40px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    @include breakpoint(460px) {
        margin-top: -26px;
        width: 54px;
        height: 54px;
    }
    span[role="presentation"] {
        display: flex;
        justify-content: center;
    }
    .icon-wrapper {
        display: flex;
    }
    svg {
        fill: $color-white;
        @include breakpoint(460px) {
            width: 29px;
            height: 12px;
        }
    }
    &.slick-disabled {
        display: none !important;
    }
}
.series-carrousel-slider-prev,
.series-carrousel-slider-next {
    top: 50%;
}
.four-slides-slider-prev,
.series-carrousel-slider-prev {
    left: 0;
}
.four-slides-slider-next,
.series-carrousel-slider-next {
    right: 0;
}
.four-slide,
.serie-slide {
    margin: 5px 12px;
    opacity: 0;
    .slick-initialized & {
        opacity: 1;
    }
    .flag-container {
        position: absolute;
        top: 0;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
    }
    &:hover {
        .animate {
            svg {
                transform: translateX(35%);
            }
        }
    }
}
.four-slide-inner {
    max-width: 300px;
    margin: 0 auto;
    @include breakpoint(medium) {
        max-width: none;
        margin: 0;
    }
}
.four-slide-price {
    color: $color-white;
    margin-top: rem(6px);
}
.four-slide-special-link {
    background: $color-ocean-green;
    display: block;
    padding-bottom: 139%;
    height: 0;
    position: relative;
}
.four-slide-special-text {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-flow: column nowrap;
    font-family: $f-display;
    @include rem-typo(24, 30);
    letter-spacing: rem(2px);
    color: $color-white;
    text-align: center;
    text-transform: uppercase;
    .icon-wrapper {
        margin-top: 20px;
    }
    svg {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        fill: $color-white;
    }
    &:hover {
        svg {
            fill: $color-white;
            transform: translateX(35%);
        }
    }
}
