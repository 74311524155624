.series-telus {
    .serie-header {
        position: relative;
        background: linear-gradient(60deg, $color-jacarta 0%, $color-black 75%, $color-black 100%);
        padding: 60px 0 90px;
        @include breakpoint(large) {
            padding: 90px 0 380px;
            background: linear-gradient(45deg, $color-jacarta 0%, $color-black 90%, $color-black 100%);
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 50%;
            max-width: 650px;
            height: 60%;
            top: 0;
            right: 0;
            background-image: url("../img/telus/telus-radial.svg");
            background-size: 200%;
            background-position: left 110%;
            background-repeat: no-repeat;
            @include breakpoint(medium) {
                height: 100%;
                background-size: 130%;
                background-position: left 15%;
            }
        }
        .serie-title {
            width: 260px;
            @include breakpoint(large) {
                width: 460px;
            }
            img {
                margin-bottom: 32px;
                @include breakpoint(medium) {
                    margin-bottom: 40px;
                }
            }
        }
    }
    .serie-content {
        position: relative;
        &::before {
            content: "";
            background-color: $color-true-black;
            width: 100%;
            height: 100%;
            display: block;
            position: absolute;
            z-index: -1;
        }
        &::after {
            content: "";
            position: absolute;
            height: calc(100% - 700px);
            width: 100%;
            bottom: 0;
            left: 0;
            background-image: url("../img/telus/telus-background-radial-mobile.svg");
            background-size: 100%;
            background-repeat: repeat-y;
            z-index: -1;
            @include breakpoint(large) {
                background-image: url("../img/telus/telus-background-radial.svg");
            }
        }
        .event-card-inner,
        .event-card-infos {
            &:hover,
            &:focus {
                color: $color-white;
                .event-card-infos {
                    color: $color-white;
                }
                .event-svg-container svg {
                    fill: $color-mountains-majesty;
                }
            }
        }
        .event-card-infos {
            color: $color-white;
        }
        .buy-link-additional {
            background-color: $color-jacarta;
        }
        .buy-available-link {
            color: $color-jacarta;
        }
        .event-svg-container svg {
            fill: $color-mountains-majesty;
        }
    }
}
