.studio-telus {
    position: relative;
    background-color: $color-cod-grey;
    height: calc(100vh + 100px);
    width: 100%;
    overflow: hidden;
    user-select: none;
    @media (prefers-reduced-motion) {
        height: 100vh;
    }
    .telus-content,
    .telus-radial {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
    }
    .telus-radial {
        max-width: none;
        @media (prefers-reduced-motion) {
            transform: translate(-50%, -100%) scale(1.5);
        }
    }
    .telus-content {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        flex-direction: column;
        justify-content: center;
        @media (prefers-reduced-motion) {
            position: relative;
        }
    }
    .telus-title {
        width: 600px;
        max-width: 85%;
        @media (prefers-reduced-motion) {
            position: static;
            transform: initial;
            width: 350px;
        }
    }
    .telus-subtitle {
        @include rem-typo(18px);
        font-family: $f-display;
        font-weight: 900;
        margin-top: 16px;
        color: $color-white;
        letter-spacing: 2px;
        @include breakpoint(medium) {
            @include rem-typo(38px);
            letter-spacing: 5px;
        }
        @media (prefers-reduced-motion) {
            margin-bottom: 40px;
            @include breakpoint(medium) {
                @include rem-typo(24px);
            }
        }
    }
    .show-image {
        position: absolute;
        border-radius: 50%;
        border: 5px solid transparent;
        background: linear-gradient(to bottom, $color-jacarta, $color-cardinal);
        background-origin: border-box;
        width: 75px;
        height: 75px;
        @include breakpoint(medium) {
            border-width: 10px;
            width: 150px;
            height: 150px;
        }
        @media (prefers-reduced-motion) {
            display: none;
        }
        &:nth-child(1) {
            top: 19%;
            left: -5%;
            width: 90px;
            height: 90px;
            @include breakpoint(medium) {
                top: 5%;
                left: 15%;
                width: 170px;
                height: 170px;
            }
        }
        &:nth-child(2) {
            top: 27%;
            left: 45%;
            @include breakpoint(medium) {
                top: 15%;
                left: 42%;
            }
        }
        &:nth-child(3) {
            top: 12%;
            right: -5%;
            width: 110px;
            height: 110px;
            @include breakpoint(medium) {
                top: 8%;
                width: 200px;
                height: 200px;
            }
            @include breakpoint(large) {
                right: 18%;
            }
        }
        &:nth-child(4) {
            top: -2%;
            left: 25%;
            width: 110px;
            height: 110px;
            @include breakpoint(medium) {
                top: initial;
                bottom: 25%;
                left: -4%;
                width: 200px;
                height: 200px;
            }
        }
        &:nth-child(5) {
            bottom: 25%;
            left: 32%;
            @include breakpoint(medium) {
                bottom: 8%;
                left: 22%;
            }
        }
        &:nth-child(6) {
            bottom: 10%;
            left: 10%;
            width: 90px;
            height: 90px;
            @include breakpoint(medium) {
                bottom: 6%;
                right: 28%;
                left: initial;
                width: 170px;
                height: 170px;
            }
        }
        &:nth-child(7) {
            bottom: 15%;
            right: -4%;
            @include breakpoint(medium) {
                bottom: 25%;
                right: 1%;
            }
        }
    }
    .studio-tiles {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1000px;
        max-width: calc(100% - 24px);
        margin: 0 auto;
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        gap: 10px;
        align-items: flex-start;
        @include breakpoint(medium) {
            gap: 24px;
        }
        @include breakpoint(large) {
            top: 55%;
        }
        @media (prefers-reduced-motion) {
            position: static;
            transform: initial;
        }
    }
    .studio-tile {
        position: relative;
        @include breakpoint(medium) {
            margin-top: -80px;
        }
        &:nth-child(2) {
            margin-top: 70px;
            @include breakpoint(medium) {
                margin-top: 0;
            }
        }
        &:hover {
            .tile-cta {
                transform: scale(1.1);
            }
        }
    }
    .studio-tile-image {
        width: 100%;
        aspect-ratio: 1 / 1.3;
        object-fit: cover;
        @include breakpoint(medium) {
            aspect-ratio: 1 / 1;
        }
    }
    .studio-cta-wrapper {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translate(-50%, 50%);
        width: 90%;
        @include breakpoint(medium) {
            width: 75%;
        }
        .tile-cta {
            @include rem-typo(12px);
            display: block;
            background-color: $color-jacarta;
            color: $color-white;
            padding: 12px;
            text-transform: uppercase;
            font-family: $f-display;
            font-weight: 900;
            text-align: center;
            letter-spacing: 2px;
            width: 100%;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            @include breakpoint(medium) {
                @include rem-typo(18px);
                padding: 24px;
            }
        }
    }
}
