.event-view-toggle {
    display: flex;
    justify-content: space-between;
    list-style: none;
    margin: 0;
    padding: 0;
    li {
        padding-bottom: rem(12px);
        button {
            outline: none;
            @include rem-typo(14px, 20px);
            font-family: $f-display;
            letter-spacing: 0.01em;
            text-transform: uppercase;
            position: relative;
            cursor: pointer;
            transition: all 0.6s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &::before {
                position: absolute;
                bottom: -18px;
                left: 0;
                content: "";
                background: $color-white;
                height: 6px;
                width: 0;
            }
            &:hover {
                color: $color-ocean-green;
            }
            @include breakpoint(large) {
                @include rem-typo(18px, 26px);
            }
        }
        &.current-mode {
            button {
                &::before {
                    width: 68px;
                    transition: width 200ms;
                }
            }
        }
    }
}
