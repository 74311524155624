.block-textimage {
    &.large-image {
        .text {
            @include breakpoint (1200px) {
                max-width: 530px;
            }
        }
        .img {
            margin-left: 0;
            @include breakpoint(large) {
                padding-right: 0;
            }
            img {
                max-width: none;
                width: calc(100% + 20px);
                margin-right: -20px;
                @include breakpoint(large) {
                    width: calc(100% + 105px);
                }
                @include breakpoint(1200px) {
                    width: calc(100% + 180px);
                }
            }
        }
    }
    &.image-left {
        .img {
            img {
                margin-right: 0;
                @include breakpoint(large) {
                    margin-left: -20px;
                    padding-right: 20px;
                }
            }
        }
        &.large-image {
            .img {
                img {
                    margin-left: -20px;
                }
            }
        }
        @include breakpoint(large) {
            &:not(.large-image) {
                .img {
                    text-align: right;
                }
            }
        }
    }
}
.block-textimage-credits {
    @include rem-typo(14, 20);
    color: $color-masala;
    margin-top: 10px;
}
