.series-gvq {
    background-color: $color-onahau;
    .serie-header {
        position: relative;
        padding: 60px 0 90px;
        overflow: visible;
        z-index: 1;
        color: $color-black;
        @include breakpoint(large) {
            padding: 90px 0 380px;
        }
        &::before {
            content: "";
            position: absolute;
            top: 0;
            left: 0;
            background-image: url("../img/gvq/top-left.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top right;
            line-height: 0;
            width: 393px;
            max-width: 40%;
            aspect-ratio: 131 / 103;
        }
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            background-image: url("../img/gvq/top-right.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: top right;
            line-height: 0;
            width: 685px;
            max-width: 50%;
            aspect-ratio: 685 / 685;
        }
        .serie-header-text {
            display: grid;
            grid-template-columns: 7fr 3fr;
            gap: 16px 24px;
            @include breakpoint(medium) {
                max-width: none;
            }
        }
        .serie-title {
            display: block;
            margin-bottom: 1rem;
            grid-column: 1 / span 1;
            margin: 0;
            @include breakpoint(medium) {
                max-width: 740px;
            }
            img {
                height: 100%;
            }
        }
        .serie-description {
            grid-column: 1 / span 2;
            max-width: 800px;
            @include breakpoint(medium) {
                grid-column: 1 / span 1;
            }
        }
        .gvq-badge {
            margin-right: -36px;
            grid-row: 1;
            grid-column: 2;
            align-self: center;
            justify-self: center;
            @include breakpoint(medium) {
                grid-row: 1 / span 2;
                margin-right: 0;
            }
        }
    }
    .serie-content {
        position: relative;
        &::before {
            content: "";
            position: absolute;
            bottom: 50%;
            transform: translateY(50%);
            left: 0;
            width: 251px;
            background-image: url("../img/gvq/bottom-left.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom left;
            line-height: 0;
            max-width: 40%;
            aspect-ratio: 251 / 1418;
        }
        &::after {
            content: "";
            position: absolute;
            bottom: 0;
            right: 0;
            width: 998px;
            background-image: url("../img/gvq/bottom-right-2.png");
            background-size: contain;
            background-repeat: no-repeat;
            background-position: bottom right;
            line-height: 0;
            max-width: 90%;
            aspect-ratio: 998 / 434;
            @include breakpoint(large) {
                max-width: 70%;
                width: 799px;
                background-image: url("../img/gvq/bottom-right.png");
                aspect-ratio: 799 / 914;
            }
        }
        .event-card-inner,
        .event-card-infos {
            &:hover,
            &:focus {
                color: $color-black;
                .event-card-infos {
                    color: $color-black;
                }
                .event-svg-container svg {
                    fill: $color-seance;
                }
            }
        }
        .buy-link-additional {
            background-color: $color-seance;
        }
        .buy-available-link {
            color: $color-seance;
        }
        .event-svg-container svg {
            fill: $color-seance;
        }
    }
}
