table {
    margin-bottom: 32px;
    padding: rem(60px) rem(16px) 0;
    width: 100%;
    border-bottom: solid 1px $color-black;
    @include breakpoint(medium) {
        @include rem-typo(18px);
        padding-top: rem(88px);
    }
}
tr {
    border-top: solid 1px $color-black;
}
th,
td {
    padding: rem(15px) rem(8px);
    @include breakpoint(medium) {
        padding: rem(24px) rem(8px);
    }
}
th {
    text-align: left;
}
