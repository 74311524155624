.social-links {
    display: flex;
    list-style: none;
    padding: 0;
    margin: 0;
}
.social-icon {
    padding-top: rem(13px);
    padding-right: rem(41px);
    fill: $color-white;
    &:hover {
        fill: $color-ocean-green;
    }
}
