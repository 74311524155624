.newsletter-cta {
    background-size: cover;
    padding: rem(22px);
    display: flex;
    flex-flow: column;
    align-items: flex-start;
    min-height: auto;
    @include breakpoint(medium) {
        background-position: -150px top;
        min-height: rem(430px);
        padding: rem(55px);
    }
    @include breakpoint(900px) {
        background-position: center top;
    }
    @include breakpoint(large) {
        min-height: rem(430px);
        padding: rem(55px) rem(100px);
    }
}
.newsletter-cta-title {
    @include rem-typo(28, 28);
    width: 100%;
    color: $color-white;
    font-family: $f-display;
    font-weight: 800;
    text-transform: uppercase;
    margin-bottom: rem(30px);
    @include breakpoint(medium) {
        @include rem-typo(64, 64);
    }
}
.newsletter-cta-text {
    @include rem-typo(18, 28);
    font-family: $f-titles;
    font-weight: bold;
    width: 100%;
    margin-bottom: rem(20px);
    span {
        display: block;
        @include breakpoint(medium) {
            margin-right: rem(20px);
            display: inline-block;
        }
    }
}
.newsletter-cta-btn {
    background: $color-masala;
    margin-top: auto;
    &:hover {
        transform: scale(1.1);
        color: $color-ocean-green;
    }
}
.newsletter-social-links {
    margin-top: 35px;
    .social-icon {
        svg {
            fill: $color-black;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        &:hover {
            svg {
                fill: $color-white;
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
        }
    }
    li {
        &:last-child {
            .social-icon {
                padding-right: 0;
            }
        }
    }
}
