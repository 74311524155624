// ** Buttons **
.btn {
    @include rem-typo(14);
    display: inline-block;
    background: $color-masala;
    color: $color-white-rock;
    border: 0;
    padding: 16px;
    letter-spacing: rem(1.5px);
    font-family: $f-display;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    @include breakpoint(medium) {
        @include rem-typo(16);
        letter-spacing: rem(2.5px);
    }
    &.btn--in-aside {
        background: $color-white;
        color: $color-ocean-green;
        padding: 20px 16px 24px;
        max-width: 260px;
        width: 100%;
        &:hover {
            transform: scale(1.1);
        }
    }
}
