.primary-nav {
    display: flex;
}
.primary-nav-items {
    flex-grow: 1;
    display: flex;
    justify-content: flex-end;
    align-items: center;
}
.primary-nav-item {
    position: relative;
    margin-left: rem(16px);
    margin-right: rem(16px);
    &:first-child {
        margin-left: 0;
    }
    &:last-child {
        margin-right: 0;
        .submenu {
            left: auto;
            right: 0;
        }
    }
    &.has-submenu {
        .primary-nav-link {
            padding-right: rem(20px);
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 0;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $color-white;
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            &:hover,
            &.active,
            &.ancestor {
                &::after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-top: 5px solid $color-ocean-green;
                    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
                }
            }
        }
        &.is-open {
            .submenu {
                max-height: 800px;
                opacity: 1;
                transform: translate(0, 0);
            }
            .primary-nav-link {
                &::after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid $color-white;
                    border-top: 0;
                }
                &:hover,
                &.active,
                &.ancestor {
                    &::after {
                        border-left: 5px solid transparent;
                        border-right: 5px solid transparent;
                        border-bottom: 5px solid $color-ocean-green;
                        border-top: 0;
                    }
                }
            }
        }
    }
    .submenu {
        display: none;
        z-index: 2;
        position: absolute;
        top: 100%;
        left: 0;
        padding: rem(10px) rem(20px) rem(16px);
        background: $color-masala;
        width: 240px;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        max-height: 0;
        opacity: 0;
        transform: translate(0, 50%);
        li {
            display: block;
            border-bottom: solid 1px $color-ocean-green;
            &:last-child {
                border-bottom: 0;
            }
        }
        a {
            display: inline-block;
            padding: rem(13px) 0;
            color: $color-white;
            @include rem-typo(14, 16);
            font-family: $f-display;
            font-weight: 700;
            letter-spacing: rem(1.4px);
            text-transform: uppercase;
            &:hover,
            &.active {
                color: $color-ocean-green;
            }
        }
    }
    @include breakpoint(medium) {
        margin-left: rem(22px);
        margin-right: rem(22px);
    }
}
.primary-nav-link {
    position: relative;
    display: inline-block;
    @include rem-typo(18, 24);
    font-weight: 700;
    font-family: $f-display;
    letter-spacing: rem(1.5px);
    color: $color-white;
    text-align: left;
    text-transform: uppercase;
    padding: rem(17px) 0;
    &:hover,
    &.active,
    &.ancestor {
        color: $color-ocean-green;
    }
    @include breakpoint(large) {
        @include rem-typo(16, 24);
    }
    @include breakpoint(1280px) {
        @include rem-typo(18, 24);
        padding: rem(17px) 0;
    }
}
