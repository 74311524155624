.block-images-list {
    margin: 40px 0;
    display: grid;
    grid-template-columns: repeat(auto-fill, 160px);
    justify-content: center;
    gap: 32px 16px;
    @include breakpoint(medium) {
        grid-template-columns: repeat(auto-fill, 190px);
    }
    .image-item {
        margin: auto;
    }
}
