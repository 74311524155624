.series-bigbang {
    background-color: $color-aqua-island;
    .serie-header {
        background-color: $color-jagged-ice;
        color: $color-black;
        padding: 32px 0 240px;
        @include breakpoint(medium) {
            padding-top: 24px;
        }
        &::before {
            content: "";
            display: block;
            position: absolute;
            width: 50%;
            max-width: 200px;
            height: 240px;
            bottom: 0;
            left: -60px;
            background-image: url("../img/bigbang/bird-mobile.png");
            background-size: 100%;
            background-repeat: no-repeat;
            @include breakpoint(medium) {
                left: initial;
                right: 16px;
                height: 500px;
                max-width: 35vw;
                background-position: center top;
                background-image: url("../img/bigbang/bird.png");
            }
            @include breakpoint(large) {
                height: calc(100% - 50px);
                right: 50px;
            }
        }
        .serie-header-text {
            max-width: 480px;
            padding: 0 4px;
            @include breakpoint(medium) {
                max-width: 60%;
            }
        }
    }
    .serie-title-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 8px;
        @include breakpoint(large) {
            width: 50vw;
            max-width: 700px;
            margin-bottom: 40px;
        }
    }
    .serie-title {
        width: 100%;
        max-width: 700px;
        margin: 0;
        @include breakpoint(large) {
            max-width: 60%;
        }
        img {
            margin-bottom: 8px;
        }
    }
    .serie-content,
    .block-content-page {
        position: relative;
        &::after {
            content: "";
            position: absolute;
            height: calc(100% - 700px);
            width: 100%;
            bottom: 0;
            left: 0;
            background-image: url("../img/bigbang/stars-background-mobile.svg");
            background-size: 100%;
            background-repeat: repeat-y;
            z-index: -1;
            @include breakpoint(large) {
                background-image: url("../img/bigbang/stars-background.svg");
            }
        }
    }
    .buy-link-additional {
        background-color: $color-denim;
    }
    .buy-available-link {
        color: $color-denim;
    }
    .event-svg-container svg {
        fill: $color-rhino;
    }
    .serie-events-list {
        margin-top: -175px;
        .event-list {
            position: relative;
            &::after {
                content: "";
                background-image: url("../img/bigbang/star.svg");
                width: 80px;
                height: 80px;
                background-size: 100%;
                position: absolute;
                top: 0;
                right: 0;
                transform: translate(-12%, -40%);
                z-index: -1;
                @include breakpoint(medium) {
                    width: 100px;
                    height: 100px;
                    transform: translate(25%, -40%);
                }
            }
        }
    }
    .block-content-page {
        padding-bottom: rem(32px);
        @include breakpoint(medium down) {
            &::after {
                display: none;
            }
        }
        @include breakpoint(medium) {
            padding-bottom: rem(72px);
        }
        .btn {
            background-color: $color-monza;
            color: $color-white;
        }
        .aside-details {
            display: flex;
            flex-direction: column;
            gap: 24px;
            padding: 40px;
            @include breakpoint(medium) {
                padding: 50px 45px 50px 90px;
            }
            p {
                margin: 0;
            }
        }
        .block-textimage {
            padding: 40px 0 24px;
            > .grid-container {
                > .grid-x {
                    justify-content: center;
                }
            }
        }
        .block-container {
            &:last-child {
                margin-bottom: 0;
            }
            > .medium-10 {
                margin: auto;
            }
        }
        .block-slider {
            > .grid-x {
                justify-content: center;
            }
            .medium-offset-1 {
                margin: auto;
            }
        }
        a:not(.btn) {
            &:hover {
                color: $color-monza;
            }
        }
    }
}
