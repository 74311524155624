.price-row {
    display: flex;
    justify-content: space-between;
    border-top: solid 1px $color-grey-mineshaft;
    padding-top: 16px;
    padding-bottom: 16px;
    &:last-child {
        border-bottom: solid 1px $color-grey-mineshaft;
    }
    &.price-row--header {
        border-top: 0;
        padding-bottom: 9px;
        .price-label {
            font-weight: bold;
            text-transform: none;
        }
        .price-value {
            font-weight: normal;
        }
        @include breakpoint(medium) {
            padding-bottom: 18px;
        }
    }
    &.price-row--card {
        @include breakpoint(medium) {
            padding-top: 16px;
            padding-bottom: 16px;
        }
    }
    @include breakpoint(medium) {
        padding-top: 24px;
        padding-bottom: 24px;
    }
}
.price-label {
    @include rem-typo(14, 16);
    text-transform: uppercase;
    letter-spacing: 0.1em;
    padding-right: 16px;
    @include breakpoint(medium) {
        @include rem-typo(18, 21);
    }
    .price-row--card & {
        font-weight: 300;
        text-transform: none;
        @include breakpoint(medium) {
            @include rem-typo(14, 16);
        }
    }
}
.price-value {
    @include rem-typo(14, 16);
    text-align: right;
    font-weight: bold;
    letter-spacing: 0.1em;
    @include breakpoint(medium) {
        @include rem-typo(18, 21);
    }
    .price-row--card & {
        @include breakpoint(medium) {
            @include rem-typo(14, 16);
        }
    }
}
