.section-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    > a {
        display: none;
        @include rem-typo(14, 20);
        font-family: $f-display;
        font-weight: 800;
        text-transform: uppercase;
        margin-top: -10px;
        letter-spacing: rem(2.5px);
        .icon-wrapper {
            margin-left: 14px;
        }
        @include breakpoint(medium) {
            display: flex;
        }
        svg {
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
        &:hover {
            svg {
                transform: translateX(35%);
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
        }
    }
    @include breakpoint(medium) {
        margin-bottom: 24px;
        .home-partners & {
            margin-bottom: 28px;
        }
    }
}
.section-half-bg {
    background-image: linear-gradient(0deg, $color-black 50%, $color-offwhite 50%);
}
.section-dark-bg {
    background: $color-black;
    &.loading {
        min-height: 80vh;
    }
}
.section-title {
    color: $color-masala;
    &.section-title--invert {
        color: $color-white-rock;
    }
}
.section-promo {
    position: relative;
    &::before {
        content: "";
        display: block;
        width: 100%;
        height: 50%;
        background-color: $color-black;
        position: absolute;
        top: 0;
        @include breakpoint(medium) {
            height: 60%;
        }
    }
    .section-content {
        position: relative;
        z-index: 1;
    }
    .promo-image {
        display: block;
        margin: auto;
        aspect-ratio: 7 / 10;
        object-fit: cover;
        @include breakpoint(medium) {
            aspect-ratio: 16 / 5;
        }
    }
}

.section {
    &.home-residents {
        position: relative;
        padding-bottom: 60px;
        @include breakpoint(medium) {
            padding-bottom: 100px;
        }
        @include breakpoint(large) {
            padding-bottom: 200px;
        }
        &::before {
            content: "";
            width: 100%;
            height: calc(100% - 180px);
            background-color: $color-black;
            position: absolute;
            top: 180px;
            z-index: -1;
            @include breakpoint(medium) {
                height: calc(100% - 230px);
                top: 230px;
            }
        }
    }
}
