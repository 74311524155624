.presenters {
    margin-top: 50px;
    @include breakpoint(medium) {
        margin-top: 150px;
    }
}
.presenters-title {
    @include rem-typo(16, 19);
    letter-spacing: 0.1em;
    margin-bottom: 0;
    @include breakpoint(medium) {
        @include rem-typo(24, 28);
    }
}
.presenter {
    margin-top: 42px;
    @include breakpoint(medium) {
        margin-top: 58px;
    }
}
