.offer-tag {
    position: absolute;
    top: -6px;
    right: -6px;
    background-color: $color-shadow;
    color: $color-white;
    display: flex;
    align-items: center;
    z-index: 1;
    padding: 8px;
    font-family: $font-neue;
    text-transform: uppercase;
    @include rem-typo(14px, 16px);
    text-align: left;
    svg {
        fill: $color-white;
        margin-right: 8px;
    }
}
