.home-partners {
    position: relative;
    background-color: $color-cod-grey;
    color: $color-white;
    @include breakpoint(small only) {
        h2 {
            margin-bottom: rem(30px);
        }
    }
    .home-partners-background-image {
        opacity: 0.2;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
.home-partners-content {
    position: relative;
    z-index: 1;
}
.home-newsletter-wrapper {
    padding-bottom: 80px;
    margin-bottom: 50px;
    border-bottom: 1px solid rgba($color-white, 0.3);
    .grid-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        gap: 48px 24px;
        flex-wrap: wrap;
    }
    .newsletter-title {
        @include rem-typo(20px);
        color: $color-satin-linen;
        letter-spacing: 1px;
    }
    .newsletter-text {
        @include rem-typo(40px);
        font-family: $f-display;
        font-weight: 900;
        line-height: 1;
        margin: 0;
        text-transform: uppercase;
        letter-spacing: 1px;
    }
    .newsletter-btn {
        background-color: $color-ocean-green;
        color: $color-white;
        &:hover,
        &:focus {
            transform: scale(1.1);
        }
    }
}
.partner-group-title {
    @include rem-typo(16, 19);
    letter-spacing: rem(0.86);
    font-weight: bold;
    font-family: $f-default;
    margin-bottom: rem(20px);
    @include breakpoint(medium) {
        margin-bottom: rem(16px);
    }
}
.partners-list {
    // sass-lint:disable no-misspelled-properties
    display: flex;
    gap: 24px;
    flex-wrap: wrap;
    margin-bottom: 40px;
    @include breakpoint(large) {
        gap: 48px;
    }
}
.partners {
    margin: 0;
    list-style: none;
}
.partner {
    width: 100%;
    min-height: 55px;
    margin-bottom: 38px;
    @include breakpoint(medium) {
        width: auto;
        min-height: 90px;
        margin-right: 38px;
        margin-bottom: 0;
        &:last-child {
            margin-right: 0;
        }
    }
    a,
    div {
        height: 100%;
    }
    img {
        filter: invert(1) brightness(1000%);
    }
}
