.ie11 {
    .foule-cool {
        .aside-container {
            z-index: 1;
        }
        .aside-details {
            &.aside--black {
                background: $color-black;
                @include breakpoint(medium) {
                    background: $color-black;
                    padding: rem(70px) rem(30px);
                }
            }
        }
        .foule-cool-page-content {
            .side-content {
                background: $color-masala;
                @include breakpoint(medium) {
                    padding: 44px 30px 70px 55px;
                    background: $color-masala;
                }
                @include breakpoint(large) {
                    padding: 70px 30px 70px 55px;
                    background: $color-masala;
                }
            }
        }
    }
}
.foule-cool {
    .aside-container {
        margin-bottom: 0;
    }
    .aside-details {
        text-align: center;
        strong {
            @include rem-typo(22px);
            color: $color-white-rock;
            font-family: $f-titles;
            letter-spacing: rem(2.44px);
        }
        h2 {
            @include rem-typo(40px);
            font-family: $f-display;
            color: $color-white;
            text-transform: uppercase;
            line-height: rem(40px);
            margin: auto;
            padding-top: rem(26px);
            padding-bottom: rem(42px);
        }
        @include breakpoint(medium) {
            padding: 44px 30px 44px 90px;
        }
        @include breakpoint(large) {
            padding: 70px 30px 70px 90px;
        }
    }
    .foule-cool-details-description {
        @include rem-typo(20px);
        color: $color-white-rock;
        font-weight: bold;
        letter-spacing: rem(1.67px);
        line-height: rem(24px);
        display: block;
        margin: auto;
    }
    .foule-cool-page-content {
        position: relative;
        @include breakpoint(medium) {
            padding-top: rem(112px);
            min-height: 750px;
        }
        @include breakpoint(large) {
            min-height: 650px;
        }
        .side-content {
            background-image: linear-gradient($color-masala);
            color: $color-white;
            padding: rem(48px) rem(16px);
            @include breakpoint(medium) {
                background-image: linear-gradient(82deg, $c-body-bg 25%, $color-masala calc(25% + 1px));
                padding: 80px 7% 80px 14%;
                margin-top: 0;
                position: absolute;
                right: 0;
                top: rem(112px);
            }
        }
        .side-content-instructions {
            @include rem-typo(22px);
            line-height: rem(28px);
            letter-spacing: rem(1.65px);
            font-family: $f-titles;
            color: $color-white-rock;
            @include breakpoint(medium) {
                max-width: 302px;
            }
        }
        .side-content-link {
            cursor: pointer;
            font-family: $f-display;
            letter-spacing: rem(1.34px);
            text-transform: uppercase;
            padding: rem(22px) 0;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid $color-ocean-green;
            margin-top: rem(44px);
            @include breakpoint(medium) {
                @include rem-typo(18px);
                letter-spacing: rem(1.5px);
                padding: rem(35px) 0;
                margin-top: 0;
                max-width: 302px;
            }
            &:hover {
                .arrow {
                    svg {
                        transform: translateX(35%);
                    }
                }
                .logo {
                    fill: $color-ocean-green;
                }
            }
            svg {
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            .arrow {
                margin-left: rem(30px);
                fill: $color-ocean-green;

            }
            .logo {
                margin: rem(10px) 0 0;
                width: 80%;
            }
        }
        .side-content-warning {
            @include rem-typo(18px);
            font-weight: 500;
            letter-spacing: rem(1.5px);
            line-height: rem(22px);
            margin-top: rem(36px);
            @include breakpoint(medium) {
                max-width: 302px;
            }
        }
        h2,
        h3 {
            letter-spacing: rem(2.1px);
            margin-bottom: rem(12px);
        }
        h3 {
            @include rem-typo(24px);
            @include breakpoint(medium) {
                @include rem-typo(28px);
            }
        }
        ul,
        p {
            letter-spacing: rem(0.6px);
            line-height: rem(30px);
            margin-bottom: rem(44px);
            &:last-child {
                margin-bottom: 0;
            }
            @include breakpoint(medium) {
                @include rem-typo(18px);
            }
        }
        b {
            @include rem-typo(20px);
            line-height: rem(34px);
            letter-spacing: rem(1px);
        }
        ul {
            list-style-type: none;
            li {
                margin-bottom: rem(16px);
                line-height: rem(18px);
                position: relative;
                @include breakpoint(medium) {
                    margin-bottom: rem(32px);
                    line-height: rem(32px);
                }
                &::before {
                    top: rem(6px);
                    @include breakpoint(medium) {
                        top: rem(16px);
                        left: rem(-24px);
                    }
                }
                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }
}
