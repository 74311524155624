.event-item-wrapper {
    @include breakpoint(500px down) {
        margin: 0 !important;
        width: 100% !important;
    }
}
.event-card {
    text-align: center;
    &.small-6 {
        @include breakpoint(600px down) {
            width: 100%;
        }
    }
}
.event-card-inner {
    color: $color-black;
    width: 100%;
    height: 100%;
    position: relative;
    @include breakpoint(600px down) {
        max-width: 300px;
        margin: 0 auto;
    }
    &:hover {
        .no-hover-link {
            color: $color-black;
        }
        .animate {
            svg.available {
                transform: translateX(35%);
            }
            svg {
                .four-slide & {
                    transform: translateX(35%);
                }
            }
        }
    }
    &:active,
    &:focus,
    &:visited {
        color: $color-black;
    }
    // CARD IMAGE
    .poster-image-container {
        padding-bottom: 139%;
    }
}

.event-card-btn-container {
    position: relative;
    max-width: 100%;
    a {
        max-width: 100%;
    }
}
//CARD INFOS
.event-card-header-container {
    width: 100%;
}
.event-card-category {
    min-height: rem(19px);
    @include rem-typo(16);
    font-family: $f-titles;
    letter-spacing: rem(2.5px);
    font-weight: 700;
    @include breakpoint(medium) {
        @include rem-typo(18);
    }
}
.event-card-title {
    @include rem-typo(22);
    text-transform: uppercase;
    font-family: $f-display;
    padding-bottom: rem(16px);
    max-width: 100%;
    @include breakpoint(medium) {
        @include rem-typo(24);
    }
}
.event-card-infos {
    width: 100%;
    flex-grow: 1;
    padding-top: rem(60px);
    padding-bottom: rem(80px);
    .event-card-info-header {
        flex-grow: 1;
    }
}
.event-card-date {
    @include rem-typo(18);
    text-transform: lowercase;
    font-weight: bold;
    letter-spacing: rem(0.5px);
    @include breakpoint(medium) {
        @include rem-typo(20);
    }
}
.event-card-price {
    @include rem-typo(14);
    letter-spacing: rem(0.47px);
    padding-bottom: rem(16px);
    @include breakpoint(medium) {
        @include rem-typo(16);
        padding-bottom: rem(28px);
    }
}
.event-svg-container {
    fill: $color-ocean-green;
    svg {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}
.no-whitespace {
    white-space: nowrap;
}
