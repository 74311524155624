.ie11 {
    body {
        display: block;
    }
    .buy-link-container {
        &.available {
            &.notcancelled {
                &:hover {
                    transform: scale(1.1) translateX(-45%);
                }
            }
        }
    }
}
.safari {
    &:not(.ios) {
        ol {
            margin-left: 2.3rem;
        }
    }
}
// Hide an element from the screen
.visuallyhidden {
    @include visuallyhidden;
}

//Change the default padding of the grid-container from foundation
.grid-container {
    padding-left: 20px;
    padding-right: 20px;
}
.header {
    .grid-container {
        padding-left: 18px;
        padding-right: 18px;
    }
}

// Force element to self-clear its children
.clearfix {
    @include clearfix;
}


.icon-wrapper {
    display: inline-block;
    vertical-align: middle;
}

// Text selection
::selection {
    color: $color-white;
    background: $color-black;
    text-shadow: none;
}

// Allow a word to break
.word-wrap {
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
}

// Font property
.foule-cool-page-content,
.show-table-description,
.sitemap,
.event-no-shows,
.rich-text {
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
        &:last-child {
            margin-bottom: 0;
        }
    }
    p {
        &:last-child {
            margin-bottom: 0;
        }
    }
    a {
        text-decoration: underline;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        overflow-wrap: break-word;
        word-wrap: break-word;
        word-break: break-word;
        &:hover {
            text-decoration: none;
        }
    }
    // Diamond li:before
    ul {
        list-style-type: none;
        margin: 0 0 0 rem(16px);
        padding: 0;
        @include breakpoint(medium) {
            margin: 0 0 0 rem(24px);
        }
        li {
            position: relative;
            padding-bottom: rem(7px);
            @include breakpoint(medium) {
                @include rem-typo(18px);
            }
            &::before {
                content: " ";
                display: block;
                position: absolute;
                top: rem(9px);
                left: rem(-16px);
                width: 6px;
                height: 6px;
                background-image: url("../svg/originals/diamond.svg");
                background-size: contain;
                @include breakpoint(medium) {
                    left: rem(-24px);
                }
            }
        }
    }
}

//IMG aspect-ratio poster
.poster-image-background-blur {
    transform: scale(1.1);
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background: $color-black;
}
.poster-image-background {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    filter: blur(5px);
    background-size: cover;
    background-position: center, center;
    opacity: 0.5;
}
.poster-image-container {
    //Ajuster le padding-bottom en fonction dela taille du ratio
    display: block;
    background-color: $c-body-bg;
    position: relative;
    width: 100%;
    height: 0;
    overflow: hidden;
    img {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        max-width: 100%;
        max-height: 100%;
        width: 100%;
    }
    &.cancelled {
        filter: grayscale(1);
        background-color: $color-black;
        .poster-image {
            opacity: 0.7;
        }
    }
    //crop-img and defaul-img must be width/height 100%
    .default-img,
    .crop-img {
        width: 100%;
        height: 100%;
    }
}

//unique property for vertical cards
.vertical-card {
    .default-image {
        width: 100%;
    }
    .flag-container {
        position: absolute;
        top: 0;
        z-index: 1;
        left: 50%;
        transform: translateX(-50%);
    }
    .buy-link-container {
        position: absolute;
        top: rem(-38px);
        &.available {
            &.additional {
                top: rem(-86px);
            }
        }
        .ie11 & {
            transform: translateX(-50%);
            left: 50%;
        }
    }
}

//Buy tickets BTN
.buy-link-container {
    z-index: 1;
    box-shadow: 0 2px 15px 4px transparentize($color-black, 0.85);
    &.available {
        &.notcancelled {
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            &.free-ticket {
                &:hover {
                    transform: none;
                }
            }
            &:hover {
                transform: scale(1.1);
            }
        }
    }
}
.buy-link-additional,
.buy-link-postponed {
    @include rem-typo(18px);
    font-family: $f-titles;
    letter-spacing: rem(1.5px);
    font-weight: bold;
    padding: rem(12px) rem(18px);
    color: $color-white;
}
.buy-link-additional {
    background-color: $color-ocean-green;
}
.buy-link-postponed {
    background-color: $color-masala;
}
.buy-available-link,
.buy-full-link,
.buy-cancelled-link {
    @include rem-typo(18);
    padding: rem(22px) rem(58px);
    font-family: $f-display;
    letter-spacing: rem(2.5px);
    text-transform: uppercase;
    display: block;
}
.buy-available-link {
    cursor: pointer;
    background-color: $color-white;
    color: $color-ocean-green;
}
.buy-full-link {
    cursor: not-allowed;
    background-color: $color-white;
    color: $color-gray;
    &:hover {
        color: $color-gray;
    }
}
.buy-cancelled-link {
    cursor: not-allowed;
    background-color: $color-black;
    color: $color-gray;
}

//Promotion flags
.flag-boxing-day,
.flag-black-friday {
    @include rem-typo(14);
    font-family: $f-display;
    letter-spacing: rem(1.4px);
    padding: rem(12px) rem(27px);
    color: $color-white;
    background-color: $color-black;
}

//Date list Modal for events
.modal-mask {
    background-color: transparentize($color-black, 0.5);
    position: fixed;
    height: 100vh;
    width: 100vw;
    z-index: 999;
    top: 0;
    left: 0;
}
.django-modal-datelist,
.django-similar-modal-datelist,
.django-slide-modal-datelist {
    display: none;
    &.is-active {
        display: flex;
    }
}
.modal-open {
    overflow-y: hidden;
}
.modal-datelist {
    background-color: $color-masala;
    color: $color-white;
    padding: rem(32px) rem(26px);
    @include breakpoint(medium) {
        padding: rem(38px) rem(38px) rem(74px);
    }

    .close-icon-container {
        order: 1;
    }
    .close-icon {
        align-self: flex-start;
        stroke: $color-white;
        padding-bottom: rem(12px);
        cursor: pointer;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        &:hover {
            stroke: $color-ocean-green;
        }
    }

    h3 {
        @include rem-typo(18px);
        letter-spacing: rem(1.11px);
        font-weight: bold;
        order: 0;
        padding-bottom: rem(18px);
        margin-bottom: 0;
        padding-top: rem(30px);

        @include breakpoint(medium) {
            @include rem-typo(20px);
            padding-top: 0;
            padding-bottom: rem(32px);
        }
    }
    ul {
        height: 100%;
        flex-grow: 1;
        padding: 0;
        margin: 0;
        list-style-type: none;
        overflow-y: auto;
    }
    p {
        margin-bottom: 0;
    }
    .modal-content {
        max-height: 65vh;
        order: 3;
        display: flex;
        flex-direction: column;
    }
    .date-row {
        padding: rem(18px) 0;
        overflow-x: hidden;
        border-bottom: 1px solid $color-ocean-green;
        &.notavailable {
            .date,
            .ticket-state {
                opacity: 0.4;
            }
        }
    }
    .date {
        font-weight: bold;
        text-transform: lowercase;
        letter-spacing: rem(0.5px);
        line-height: rem(16px);
        padding-bottom: rem(12px);

        @include breakpoint(medium) {
            @include rem-typo(18px);
            padding-bottom: 0;
        }
        p {
            &::first-letter {
                text-transform: uppercase;
            }
        }
        .hour {
            @include rem-typo(16px);
        }
    }
    .seats-left {
        @include rem-typo(13px);
        font-weight: 500;
        letter-spacing: rem(0.46px);
    }
    .ticket-state {
        span {
            @include rem-typo(18px);
            text-transform: uppercase;
            font-family: $f-display;
            letter-spacing: rem(2.5px);
        }
        svg {
            fill: $color-ocean-green;
            margin-right: rem(18px);
        }
    }
    .buy-link {
        cursor: pointer;
        span,
        svg {
            cursor: pointer;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }

        &:hover {
            span {
                color: $color-white;
                transform: scale(1.1);
            }
            svg {
                transform: translateX(35%);
            }
        }

    }
    .details-link {
        @include rem-typo(14px);
        font-family: $f-display;
        letter-spacing: rem(2px);
        padding-top: rem(18px);
        @include breakpoint(medium) {
            padding-top: rem(44px);
        }
        span,
        svg {
            margin-right: rem(20px);
        }
        svg {
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            fill: $color-ocean-green;
            margin-top: rem(12px);
            @include breakpoint(medium) {
                margin-top: 0;
            }
        }
        &:hover {
            svg {
                transform: translateX(35%);
            }
        }
    }
}

// document  style
.aside-details {
    .document {
        &::after {
            background-image: url("../svg/originals/download-icon-white.svg");
        }
    }
    .block-link {
        &:first-child {
            .btn {
                margin: 0;
                max-width: 100%;
            }
        }
    }
}
.document {
    @include rem-typo(14px);
    font-weight: 900;
    letter-spacing: rem(2.5px);
    font-family: $f-display;
    text-transform: uppercase;
    text-decoration: none !important;
    .nowrap {
        white-space: nowrap;
        margin-left: 4px;
        vertical-align: text-bottom;
        svg {
            fill: currentColor;
        }
    }
}

// ToolTip
.tippy-tooltip.white-theme {
    background-color: $color-white;
    color: $color-masala;
}
.tippy-tooltip.white-theme[data-placement^="top"] .tippy-arrow {
    border-top-color: $color-white;
}
.tippy-tooltip.white-theme[data-placement^="bottom"] .tippy-arrow {
    border-bottom-color: $color-white;
}
.tippy-tooltip.white-theme[data-placement^="left."] .tippy-arrow {
    border-left-color: $color-white;
}
.tippy-tooltip.white-theme[data-placement^="right"] .tippy-arrow {
    border-right-color: $color-white;
}
.tippy-tooltip.masala-theme {
    background-color: $color-masala;
    color: $color-white;
}
