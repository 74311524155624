.nav-toggle {
    position: absolute;
    right: 0;
    top: 50%;
    padding: 0;
    display: inline-block;
    cursor: pointer;
    margin-top: -12px;
    transition-property: opacity, filter;
    transition-duration: 0.15s;
    transition-timing-function: linear;
    @include breakpoint(medium) {
        right: 20px;
    }
    @include breakpoint(1180px) {
        display: none;
    }
    &:hover {
        opacity: 0.7;
    }
    &.is-active {
        &:hover {
            opacity: 0.7;
        }
        .nav-toggle-inner {
            transform: rotate(45deg);
            transition-delay: 0.12s;
            transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
            &::before,
            &::after {
                background-color: $color-white;
            }
            &::before {
                top: 0;
                opacity: 0;
                transition: top 0.075s ease, opacity 0.075s 0.12s ease;
            }
            &::after {
                bottom: 0;
                transform: rotate(-90deg);
                transition: bottom 0.075s ease,
                transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
            }
        }
    }
}
.nav-toggle-box {
    width: rem(20px);
    height: rem(20px); // height: $hamburger-layer-height * 3 + $hamburger-layer-spacing * 2;
    display: inline-block;
    position: relative;
    @include breakpoint(medium) {
        width: rem(40px);
        height: rem(20px);
    }
}
.nav-toggle-inner {
    display: block;
    top: 50%;
    margin-top: 1px / -2;
    transition-duration: 0.075s;
    transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
    @include breakpoint(medium) {
        margin-top: 2px / -2;
    }
    &,
    &::before,
    &::after {
        content: "";
        display: block;
        width: rem(20px);
        height: rem(1px); // hamburger-layer-height
        background-color: $color-white;
        position: absolute;
        transition-property: transform;
        transition-duration: 0.15s;
        transition-timing-function: ease;
        @include breakpoint(medium) {
            width: rem(40px);
            height: rem(2px); // hamburger-layer-height
        }
    }
    &::before {
        top: (2px + 2px) * -1; // top: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        transition: top 0.075s 0.12s ease, opacity 0.075s ease;
        @include breakpoint(medium) {
            top: (7px + 2px) * -1;
        }
    }
    &::after {
        bottom: (2px + 2px) * -1; // bottom: ($hamburger-layer-spacing + $hamburger-layer-height) * -1;
        transition: bottom 0.075s 0.12s ease,
        transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
        @include breakpoint(medium) {
            bottom: (7px + 2px) * -1;
        }
    }
}
