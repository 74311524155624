.ie11 {
    .block-media-with-aside {
        .aside-container {
            z-index: 1;
        }
        .aside-details {
            z-index: 1;
            background: $color-masala;
            @include breakpoint(medium) {
                padding: 44px 30px 70px 55px;
                background: $color-masala;
            }
            @include breakpoint(large) {
                padding: 70px 30px 70px 55px;
                background: $color-masala;
            }
        }
    }
}
.block-media-with-aside {
    position: relative;
    margin-bottom: rem(44px);
    &.negative-top-margin {
        margin-top: rem(-44px);
        @include breakpoint(medium) {
            margin-top: rem(-140px);
        }
    }
    @include breakpoint(medium) {
        min-height: 400px;
    }
    .grid-container {
        @include breakpoint(small only) {
            padding-left: 0;
            padding-right: 0;
        }
    }
    .media {
        @include breakpoint(medium) {
            margin-left: calc((1/12 * 100% * -1));
            margin-right: 0;
            width: calc(100% + (1/12 * 100%));
        }
    }
    .media-caption {
        @include rem-typo(14, 20);
        margin-top: 8px;
        @include breakpoint(small only) {
            margin-left: 25px;
            margin-bottom: 20px;
        }
    }
    .aside-details {
        background: $color-masala;
        color: $color-white;
        padding: rem(44px) rem(25px);
        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            @include rem-typo(14, 20);
            font-family: $f-display;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: rem(1px);
            color: $color-white;
        }
        dl {
            margin-bottom: 0;
            & + dl {
                margin-top: 44px;
            }
        }
        dt {
            @include rem-typo(14, 20);
            font-family: $f-display;
            font-weight: 800;
            text-transform: uppercase;
            letter-spacing: rem(1px);
            color: $color-white;
        }
        dd {
            @include rem-typo(18, 22);
            font-family: $f-default;
            font-weight: 500;
            letter-spacing: rem(0.5px);
            color: $color-white;
        }
        hr {
            border-bottom: 1px solid $color-white;
        }
        &.aside--black {
            background: $color-black;
            @include breakpoint(medium) {
                background: linear-gradient(85deg, transparent 13%, $color-black calc(13% + 1px));
            }
            @include breakpoint(large) {
                background: linear-gradient(85deg, transparent 13%, $color-black calc(13% + 1px));
            }
        }
        @include breakpoint(medium) {
            margin-top: -26px;
            margin-left: rem(-120px);
            padding: 44px 30px 44px 110px;
            background: linear-gradient(85deg, transparent 13%, $color-masala calc(13% + 1px));
        }
        @include breakpoint(large) {
            padding: 70px 30px 70px 110px;
        }
    }
}
