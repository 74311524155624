.link-list {
    margin: 0;
    list-style: none;
    li {
        border-bottom: solid 1px $color-ocean-green;
        padding-top: 36px;
        padding-bottom: 24px;
    }
    a {
        @include rem-typo(18, 24);
        font-family: $f-display;
        font-weight: 800;
        letter-spacing: rem(1.5px);
        text-transform: uppercase;
        display: flex;
        justify-content: space-between;
        color: $color-white;
        .icon-wrapper {
            margin-top: -2px;
        }
        svg {
            fill: $color-ocean-green;
        }
        &:hover {
            color: $color-ocean-green;
        }
    }
}
