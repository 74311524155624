.single-chronicle-image {
    margin-top: -124px;
    margin-bottom: rem(60px);
    background-image: linear-gradient(to bottom, #201b18 68px, #fffef5 68px);
    @include breakpoint(medium) {
        margin-bottom: rem(88px);
    }
    img {
        max-width: 100%;
        width: 1142px;
    }
}
.single-chronicle-intro {
    padding-bottom: rem(60px);
    line-height: rem(36px);
    letter-spacing: rem(1.5px);
    @include breakpoint(medium) {
        @include rem-typo(22px);
        padding-bottom: rem(88px);
    }
}
