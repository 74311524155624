// sass-lint:disable force-pseudo-nesting
.form-description {
    margin-bottom: rem(36px);
}
.form {
    margin-top: rem(20px);
    margin-bottom: rem(100px);
    label {
        @include rem-typo(16, 30);
        font-weight: bold;
        margin-bottom: rem(6px);
    }
    input[type="text"],
    input[type="email"] {
        @include rem-typo(16, 30);
        margin-bottom: rem(32px);
        background: none;
        box-shadow: none;
        border: solid 1px $color-black;
        height: auto;
        padding: rem(10px) rem(16px);
    }
    input[type="file"] {
        margin-bottom: rem(32px);
    }
    textarea {
        @include rem-typo(16, 30);
        margin-bottom: rem(32px);
        background: none;
        box-shadow: none;
        border: solid 1px $color-black;
        height: auto;
        padding: rem(10px) rem(16px);
    }
}
.form-file-upload {
    input[type="file"] {
        border: 0;
        clip: rect(0, 0, 0, 0);
        height: 1px;
        overflow: hidden;
        padding: 0;
        position: absolute !important;
        white-space: nowrap;
        width: 1px;
    }
    input[type="file"] + label {
        @include rem-typo(16, 19);
        font-weight: 500;
        background-color: $color-black;
        border-radius: rem(9px);
        color: $color-white;
        cursor: pointer;
        display: inline-block;
        padding: rem(9px) rem(21px) rem(10px);
    }
    input[type="file"]:focus + label,
    input[type="file"] + label:hover {
        background-color: $color-ocean-green;
    }
    input[type="file"]:focus + label {
        outline: 1px dotted $color-black;
    }
}
.form-file-upload-filename {
    display: inline-block;
    margin-left: 18px;
    @include breakpoint(400px down) {
        display: block;
        margin-left: 0;
    }
}
.form-submit-btn {
    display: inline-block;
    margin-top: rem(44px);
    background: $color-ocean-green;
    color: $color-white;
    border: 0;
    padding: rem(20px) rem(24px);
    letter-spacing: rem(2.5px);
    font-family: $f-display;
    font-weight: 700;
    text-align: center;
    text-transform: uppercase;
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    cursor: pointer;
    &:hover {
        transform: scale(1.1);
    }
}
.form-success-msg {
    @include rem-typo(18);
    min-height: 300px;
}

.form-error-list {
    a {
        color: $color-ocean-green;
        text-decoration: underline;
        &:hover {
            text-decoration: none;
        }
    }
}
.form-error-list-title {
    margin-bottom: 0;
}
.form-field {
    .errorlist {
        margin: 0;
        padding: 0;
        list-style: none;
        li {
            color: $color-torch-red;
        }
    }
}
.form-fieldset {
    & + .form-fieldset {
        margin-top: rem(32px);
    }
    .checkbox {
        margin-bottom: 0;
    }
}
