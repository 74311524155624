.ie11 {
    .show-share-btn-container {
        .show-share-icon {
            left: 25%;
        }
        .show-share-txt {
            &:hover {
                transform: scale(1.1) translateX(-45%);
            }
        }
    }

    .show-informations {
        aside {
            background: $color-black;
            @include breakpoint(medium) {
                background: $color-black;
            }
        }
    }
}
.show-single {
    .header {
        @include breakpoint(medium) {
            height: 210px;
        }
    }
}
.show-header {
    justify-content: center;
    padding-bottom: rem(60px);
    @include breakpoint(medium) {
        justify-content: flex-start;
        padding-bottom: rem(88px);
    }
}
.show-main-infos {
    min-height: 0;
    align-items: center;
    justify-content: center;
    padding-top: rem(22px);
    @include breakpoint(medium) {
        min-height: rem(580px);
        padding-top: rem(44px);
    }
    @include breakpoint(xlarge) {
        padding-right: rem(64px);
    }
    h1,
    .h1 {
        @include rem-typo(30px);
        font-family: $f-display;
        text-transform: uppercase;
        line-height: rem(30px);
        margin-bottom: rem(24px);
        @include breakpoint(medium) {
            @include rem-typo(36px);
            line-height: rem(36px);
            margin-bottom: rem(44px);
        }
        @include breakpoint(large) {
            @include rem-typo(38px);
            line-height: rem(38px);
        }
        span {
            overflow-wrap: break-word;
            hyphens: auto;
            display: inline-block;
        }
    }
    h2,
    .h2 {
        @include rem-typo(20px);
        font-family: $f-titles;
        font-weight: bold;
        margin-bottom: rem(30px);
        @include breakpoint(medium) {
            @include rem-typo(24px);
            margin-bottom: rem(44px);
        }
        span {
            overflow-wrap: break-word;
            hyphens: auto;
            display: inline-block;
        }
    }
}
.show-main-infos-inner {
    @include breakpoint(medium) {
        padding-right: 16px;
    }
}
.show-venue {
    @include rem-typo(14px);
    font-family: $f-display;
    text-transform: uppercase;
    letter-spacing: rem(2px);
    position: relative;
    a {
        &:hover {
            color: var(--accent-color);
        }
    }
    &::after {
        background: var(--accent-color);
        content: "";
        position: absolute;
        bottom: rem(-12);
        left: 50%;
        transform: translateX(-50%);
        width: rem(60px);
        height: rem(1px);
    }
    &.show-venue--mobile {
        display: block;
        text-align: center;
        margin-top: rem(43px);
        @include breakpoint(medium) {
            display: none;
        }
    }
    &.show-venue--desktop {
        display: none;
        @include breakpoint(medium) {
            display: block;
        }
    }
    @include breakpoint(medium) {
        letter-spacing: rem(2.5px);
    }
}
.show-category {
    font-family: $f-titles;
    margin-bottom: rem(24px);
    letter-spacing: rem(2px);
    @include breakpoint(medium) {
        @include rem-typo(18px);
        margin-bottom: rem(30px);
    }
}
.show-share-btn-container {
    margin-top: rem(44px);
    font-family: $f-titles;
    letter-spacing: rem(2px);
    position: relative;
    display: none;
    @include breakpoint(medium) {
        margin-top: rem(80px);
        display: flex;
    }
    .icon-wrapper {
        margin-top: rem(-3px);
    }
    .show-share-txt {
        cursor: pointer;
        position: absolute;
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        &:hover {
            transform: scale(1.1);
        }
    }
    .show-share-icon {
        display: none;
        transform: scale(1.1) translateY(50px);
        &.bouncein-animation {
            display: block;
            opacity: 0;
            position: absolute;
        }
        a {
            margin: 0 rem(4px);
        }
    }
}
.show-image-section {
    @include breakpoint(xlarge) {
        margin-left: rem(-44px);
        margin-right: rem(44px);
    }
    .poster-image-container {
        padding-bottom: 138%;
        margin-top: 0;
        @include breakpoint(medium) {
            margin-top: rem(-30px);
            min-height: 544px;
            min-width: 386px;
        }
        .crop-img {
            width: 100%;
        }
    }
}

.show-time-infos {
    justify-content: center;
    align-items: center;
    @include breakpoint(medium) {
        padding: 0;
        .medium-10 {
            margin-right: auto;
            padding-left: rem(16px);
        }
    }
}
.show-date-time-container {
    padding: rem(48px) 0 rem(30px);
    border-bottom: 1px solid $color-black;
    @include breakpoint(medium) {
        padding: rem(52px) 0;
    }
}
.show-multiple-dates {
    padding-bottom: rem(20px);
}
.show-dates {
    @include rem-typo(18px, 24px);
    text-transform: lowercase;
    @include breakpoint(medium) {
        @include rem-typo(20px);
    }
}
.other-dates-button {
    cursor: pointer;
    @include rem-typo(20px, 24px);
    border-bottom: 1px solid var(--accent-color);
    font-style: italic;
    font-weight: 700;
    margin-left: 4px;
    &:hover,
    &:focus {
        border-color: transparent;
    }
}
.show-time {
    letter-spacing: rem(0.54px);
    @include breakpoint(medium) {
        @include rem-typo(20);
        letter-spacing: rem(0.67px);
    }
    &.various-hours {
        @include rem-typo(16px);
        cursor: pointer;
        border-bottom: 1px solid var(--accent-color);
        &:hover {
            border-color: transparent;
        }
    }
}
.not-only-additional {
    padding-top: rem(22px);
    @include breakpoint(medium) {
        padding-top: rem(44px);
    }
}
.show-additional-dates {
    @include rem-typo(14);
    text-transform: lowercase;
    letter-spacing: rem(1.5px);
    padding-bottom: rem(10px);
    display: block;
    font-family: $f-display;
    text-transform: uppercase;
}
.show-prices {
    padding-top: rem(20px);
    @include breakpoint(medium) {
        padding: rem(44px) 0 rem(22px);
    }
    .price-title {
        padding-bottom: rem(22px) 0 0;
        h3,
        h4 {
            text-transform: uppercase;
            @include rem-typo(14px);
            font-family: $f-display;
            letter-spacing: rem(1.95px);
        }
        span {
            @include rem-typo(12px);
            letter-spacing: rem(0.4px);
        }
    }
    .price {
        position: relative;
        @include rem-typo(14px);
        letter-spacing: rem(1.5px);
        padding-bottom: rem(22px);
        strong {
            @include rem-typo(16px);
        }
        .price-tooltip {
            position: relative;
            padding: rem(10px);
            span {
                position: absolute;
                top: 0;
                width: 90%;
            }
        }
        .price-tooltip-msg {
            display: none;
            background-color: $color-masala;
            padding: rem(8px);
            position: absolute;
            top: rem(12px);
            left: rem(16px);
            transform: translateY(-100%);
            color: $color-white;
            width: rem(160px);
            @include rem-typo(12px, 12px);
            font-weight: 500;
            &.active {
                display: block;
            }
        }
    }
}
.show-open-buy-link-container {
    cursor: pointer;
    width: 100%;
    text-align: center;
    max-width: rem(340px);
    margin-top: rem(10px);
    color: $color-white;
    box-shadow: 0 2px 15px 4px transparentize($color-black, 0.85);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    .warning {
        @include rem-typo(14px);
        padding: rem(8px);
        background-color: $color-black;
    }
    .show-open-buy-link {
        display: block;
        width: 100%;
        font-family: $f-display;
        letter-spacing: rem(2px);
        padding: rem(20px) 0;
        text-transform: uppercase;
        @include breakpoint(medium) {
            @include rem-typo(18);
            letter-spacing: rem(2.5px);
            padding: rem(22px) rem(58px);
        }
        &.full {
            cursor: not-allowed;
            color: $color-white;
            background-color: $color-gray;
            &:hover,
            &:active,
            &:focus,
            &:visited {
                color: $color-white;
            }
        }
        &.cancelled,
        &.postponed {
            cursor: not-allowed;
            background-color: $color-black;
            color: $color-gray;
            &:hover,
            &:active,
            &:focus,
            &:visited {
                color: $color-gray;
            }
        }
        &:hover,
        &:active,
        &:focus,
        &:visited {
            color: $color-white;
        }
    }
    &.hover-effect-container {
        &:hover {
            transform: scale(1.1);
        }
    }
    @include breakpoint(medium) {
        margin-top: rem(22px);
    }
}
.ticket-master-link {
    font-weight: 500;
    margin-top: rem(24px);
    &:hover {
        color: $color-ocean-green;
        button {
            cursor: pointer;
        }
    }
}
.show-description {
    letter-spacing: rem(0.6px);
    line-height: rem(24px);
    padding: 0 rem(26px);

    @include breakpoint(medium) {
        @include rem-typo(18px);
        line-height: rem(30px);
        padding-bottom: rem(88px);
    }
    h2 {
        @include rem-typo(22px);
        font-family: $f-titles;
        font-weight: bold;
        letter-spacing: rem(0.74px);
        padding-bottom: rem(20px);
        margin: 0;
        @include breakpoint(medium) {
            @include rem-typo(28px);
            letter-spacing: rem(1.5px);
        }
    }
    h3 {
        padding-top: rem(14px);
    }
    .description-text {
        flex-grow: 1;
        max-width: 678px;
    }
    &.with-aside {
        @include breakpoint(medium) {
            justify-content: flex-end;
            padding-right: rem(92px);
        }
    }
}
.show-awards {
    @include rem-typo(18);
    margin: 0;
    list-style-type: none;
    font-family: $f-titles;
    letter-spacing: rem(0.6px);
    padding-bottom: rem(20px);
    @include breakpoint(medium) {
        letter-spacing: rem(0.89px);
        padding-top: rem(20px);
    }
    li {
        position: relative;
        padding-left: rem(33px);
        &::before {
            content: " ";
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 16px;
            height: 21px;
            background-image: url("../svg/originals/star.svg");
            background-size: contain;
        }
    }
    .icon-wrapper {
        margin-right: rem(18px);
    }
}
.show-keywords {
    @include rem-typo(14);
    display: flex;
    flex-wrap: wrap;
    width: fit-content;
    border: 1px solid $color-satin-linen;
    letter-spacing: rem(0.6px);
    line-height: rem(34px);
    font-family: $f-titles;
    list-style-type: none;
    margin-top: 36px;
    margin-left: 0;
    padding: 8px 16px;
    li {
        position: relative;
        &.separator {
            &::after {
                display: inline-block;
                content: "";
                width: 3px;
                height: 3px;
                background-color: $color-satin-linen;
                margin: 3px 18px;
                border-radius: 100%;
            }
            &:last-child {
                &::after {
                    display: none;
                }
            }
        }
    }
}
.show-informations {
    position: relative;
    margin-bottom: rem(44px);
    @include breakpoint(medium) {
        margin-bottom: rem(88px);
    }
    @include breakpoint(large) {
        margin-bottom: 0;
    }
    aside {
        background-image: linear-gradient($color-black);
        color: $color-white;
        padding: 0 rem(25px) rem(22px);
        @include breakpoint(medium) {
            background-image: linear-gradient(82deg, $c-body-bg 25%, $color-black calc(25% + 1px));
            padding: rem(38px) rem(76px) rem(38px) rem(150px);
        }
    }
}
.polygon-container {
    width: 100%;
    @include breakpoint(medium) {
        max-width: 300px;
    }
}
.show-more-info {
    padding: rem(30px) 0;
    @include breakpoint(medium) {
        padding: rem(38px) 0;
    }
    .block-paragraphe {
        padding-bottom: rem(30px);
        @include breakpoint(medium) {
            padding-bottom: rem(44px);
        }
    }
    strong,
    b {
        @include rem-typo(14px);
        text-transform: uppercase;
        font-family: $f-display;
        letter-spacing: rem(1.5px);
    }
    p {
        @include rem-typo(18px);
        letter-spacing: rem(0.5px);
        font-weight: 500;
        margin-bottom: 0;
    }
}
.show-other-links {
    padding: rem(30px) 0;
    @include breakpoint(medium) {
        padding: rem(38px) 0;
    }
    h3 {
        @include rem-typo(22px);
        font-family: $f-titles;
        font-weight: bold;
        letter-spacing: rem(1.2px);
        padding-bottom: rem(10px);
        @include breakpoint(medium) {
            @include rem-typo(28px);
            letter-spacing: rem(1.5px);
            padding-bottom: 0;
        }
    }
}
.show-link {
    font-family: $f-display;
    letter-spacing: rem(1.34px);
    text-transform: uppercase;
    padding: rem(22px) 0;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $color-ocean-green;
    align-items: center;
    @include breakpoint(medium) {
        @include rem-typo(18px);
        letter-spacing: rem(1.5px);
        padding: rem(35px) 0;
    }
    &:hover {
        .arrow {
            svg {
                transform: translateX(35%);
            }
        }
        .logo {
            fill: $color-ocean-green;
        }
    }
    svg {
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    .icon-wrapper {
        display: flex;
    }
    .arrow {
        margin-left: rem(24px);
        fill: $color-ocean-green;
        @include breakpoint(medium) {
            margin-left: rem(30px);
        }
    }
    .logo {
        margin: rem(10px) 0 0;
        width: 80%;
    }
}

.show-table-title {
    @include rem-typo(18px);
    display: flex;
    align-items: center;
    font-family: $f-titles;
    font-weight: bold;
    letter-spacing: rem(1px);
    width: 100%;
    border-bottom: 1px solid $color-black;
    @include breakpoint(medium) {
        @include rem-typo(20px);
        letter-spacing: rem(1.5px);
    }
}
.show-table-contact {
    margin-top: rem(12px);
    @include rem-typo(14px);
    font-family: $f-titles;
    letter-spacing: rem(1.75px);
    display: inline-block;
    @include breakpoint(medium) {
        @include rem-typo(16px);
        letter-spacing: rem(2px);
    }
    svg {
        transform: rotate(-40deg);
        fill: $color-black;
    }
}
.show-table {
    padding: 0 rem(16px) rem(60px);
    @include breakpoint(medium) {
        @include rem-typo(18px);
        padding-bottom: rem(88px);
    }
}
.show-table-row {
    padding: rem(15px) 0;
    border-bottom: 1px solid $color-black;
    justify-content: space-between;
    @include breakpoint(medium) {
        padding: rem(24px) rem(8px);
    }
}
.show-table-name,
.show-table-price {
    @include rem-typo(18px);
    text-transform: uppercase;
    font-weight: bold;
}
.show-table-name {
    order: 1;
}
.show-table-price {
    text-align: right;
    order: 2;
    @include breakpoint(medium) {
        order: 3;
    }
}
.show-table-description {
    order: 3;
    @include breakpoint(medium) {
        order: 2;
    }
    strong {
        font-family: $f-titles;
        font-weight: bold;
        line-height: rem(20px);
        letter-spacing: rem(1px);
        display: block;
    }
}
.show-discovery-section {
    margin-top: rem(60px);
    padding: rem(44px) 0;
    &.with-spotify {
        background-image: linear-gradient(to bottom, #201b18 87%, #fffef5 87%);
        @include breakpoint(large) {
            background-image: linear-gradient(to bottom, #201b18 80%, #fffef5 80%);
        }
    }
    &.without-spotify {
        background-color: $color-black;
    }
    @include breakpoint(large) {
        padding: rem(88px) 0;
    }
    .mobile-display {
        .show-spotify-container {
            margin-top: rem(30px);
        }
        .show-discovery-audio {
            margin-top: rem(44px);
        }
    }
}

.show-discovery-content {
    color: $c-body-bg;
    position: relative;
}
.show-spotify-container {
    position: relative;
    margin-bottom: rem(32px);
    @include breakpoint(large) {
        margin-bottom: 0;
    }
    &.no-slider {
        @include breakpoint(large) {
            position: relative;
        }
    }
}
.show-discovery-title {
    @include rem-typo(36px);
    font-family: $f-display;
    color: $c-body-bg;
    text-transform: uppercase;
    line-height: rem(36px);
    margin-bottom: rem(32px);
    margin-right: rem(20px);
    overflow-wrap: break-word;
    word-wrap: break-word;
    hyphens: auto;
    @include breakpoint(medium) {
        @include rem-typo(36px);
        line-height: rem(36px);
        margin-bottom: rem(44px);
    }
    @include breakpoint(large) {
        @include rem-typo(38px);
        line-height: rem(38px);
    }
    span {
        overflow-wrap: break-word;
        word-wrap: break-word;
        hyphens: auto;
        display: inline-block;
    }
}
.show-discovery-carousel {
    @include breakpoint(large) {
        margin-top: rem(-132px);
    }
    .block-slider-wrapper {
        margin: 0;
    }
    .block-slider {
        background: $color-masala;
    }
    .block-slider-caption {
        @include rem-typo(16, 20);
        color: $color-white;
    }
    .block-slider-credits {
        @include rem-typo(14, 20);
        color: $color-white;
        margin-top: 10px;
        margin-bottom: 0;
    }
    .block-slider-pagination {
        padding: 0;
    }
    .block-slider-pager {
        cursor: pointer;
        svg {
            fill: $color-dusty-gray;
        }
        &:hover {
            svg {
                fill: $color-white;
            }
        }
    }
    .slick-active {
        .block-slider-pager {
            svg {
                fill: $color-white;
            }
        }
    }
    .poster-image-container {
        padding-bottom: 65%;
        img {
            transform: translate(-50%, -50%);
        }
    }
    .iframe-wrapper {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
    }
}
.show-social-media {
    margin-bottom: rem(44px);
    h3 {
        @include rem-typo(20, 20);
        color: $color-white-rock;
        letter-spacing: rem(1.5px);
        margin-bottom: rem(12px);
    }
    .social-links {
        li {
            margin-right: 22px;
        }
    }
    .social-icon {
        padding: 0;
        display: block;
    }
    .icon-wrapper {
        fill: $c-body-bg;
    }
}
.show-discovery-audio {
    margin-bottom: rem(44px);
    .audio-item {
        & + .audio-item {
            margin-top: rem(24px);
        }
        p {
            color: $c-body-bg;
            margin-bottom: rem(8px);
        }
        audio {
            max-width: 100%;
        }
    }
}

.show-critics-container {
    padding:  rem(62px) rem(16px);
    position: relative;
    border-bottom: 1px solid $color-black;
    @include breakpoint(medium) {
        margin-top: rem(32px);
        padding: rem(88px) 0 rem(108px);
    }
}
.show-critic {
    @include rem-typo(18px);
    font-family: $f-titles;
    font-weight: bold;
    line-height: rem(22px);
    letter-spacing: rem(1.8px);
    padding-bottom: rem(30px);
    @include breakpoint(medium) {
        @include rem-typo(32px);
        line-height: rem(48px);
        letter-spacing: rem(3.2px);
        padding-bottom: rem(44px);
    }
    p {
        margin: 0;
    }
}
.show-critic-autor {
    @include rem-typo(20px);
    font-family: $f-display;
    text-transform: uppercase;
    letter-spacing: rem(0.67px);
    margin-bottom: rem(15px);
    @include breakpoint(medium) {
        @include rem-typo(32px);
        letter-spacing: rem(1.07px);
        margin-bottom: rem(20px);
    }
}
.show-critic-link {
    @include rem-typo(14px);
    font-family: $f-titles;
    letter-spacing: rem(1.75px);
    margin-bottom: rem(35px);
    display: inline-block;
    @include breakpoint(medium) {
        @include rem-typo(16px);
        letter-spacing: rem(2px);
        margin-bottom: rem(48px);
    }
    &:hover {
        color: $color-ocean-green !important;
        svg {
            fill: $color-ocean-green;
        }
    }
    &:focus,
    &:active,
    &:visited {
        color: $color-black;
    }
    svg {
        transform: rotate(-40deg);
        fill: $color-black;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}
.critics-slider {
    position: relative;
    .slick-track {
        display: flex;
        align-items: center;
    }
    .slick-dots {
        position: absolute;
        width: auto;
        bottom: -20px;
        left: 50%;
        transform: translateX(-50%);
    }
}

.critics-slider-pager {
    cursor: pointer;
    svg {
        fill: $color-dusty-gray;
    }
    &:hover,
    .slick-active & {
        svg {
            fill: $color-black;
        }
    }
}
.show-sponsors {
    padding: rem(20px) rem(26px) 0;
    flex-direction: column-reverse;
    border-bottom: 1px solid $color-black;

    @include breakpoint(medium) {
        flex-wrap: wrap;
        flex-direction: row-reverse;
        padding: rem(88px) 0;
    }
    h3 {
        @include rem-typo(20px);
        letter-spacing: rem(1.5px);
        @include breakpoint(medium) {
            @include rem-typo(28px);
            padding-bottom: rem(44px);
        }
    }
}
.sponsor-container {
    margin-bottom: rem(68px);
    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}
.sponsor {
    margin-bottom: rem(24px);
    @include breakpoint(medium) {
        margin-bottom: 0;
    }
}
.other-shows {
    padding-top: rem(88px);
    padding-bottom: rem(88px);
    @include breakpoint(medium) {
        padding-bottom: rem(120px);
    }
    h3 {
        @include rem-typo(24px);
        letter-spacing: rem(1.5px);
        line-height: rem(30px);
        padding-bottom: rem(32px);
    }
}
.arrow-container {
    position: relative;
}
.arrow-shaft {
    height: 58px;
    width: 3px;
    background-color: $color-black;
    position: relative;
    &.active {
        animation: elastic 2s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}
.arrow-head {
    height: 18px;
    width: 18px;
    border-bottom: 3.3px solid $color-black;
    border-left: 3.3px solid $color-black;
    position: absolute;
    bottom: 0;
    left: -7.01px;
    transform: rotateZ(-45deg);
}
.similar-show {
    opacity: 0;
    transform: translateY(100px);
    &:first-child {
        .other-shows {
            display: none;
        }
    }
    &.active {
        animation: appearing 2s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}
.line-separator {
    border-color: $color-white;
    margin-bottom: 0;
}
.hidden {
    display: none;
}
.bouncein-animation {
    animation: bouncein 0.3s 0.2s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
}
.slideout-animation {
    animation: slideout 1s forwards cubic-bezier(0.175, 0.885, 0.32, 1.275);
}

@keyframes slideout {
    0% {
        transform: scale(1.1);
        opacity: 1;
    }
    60% {
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 0;
    }
}
@keyframes bouncein {
    0% {
        transform: scale(1.1) translateY(50px);
        opacity: 0;
    }
    100% {
        transform: scale(1);
        opacity: 1;
    }
}
@keyframes elastic {
    0% { height: 58px; }
    20% { height: 58px; }
    50% { height: 90px; }
    65% { height: 62px; }
    68% { height: 65px; }
    75% { height: 58px; }
    100% { height: 58px; }
}
@keyframes appearing {
    0% {
        opacity: 0;
        transform: translateY(100px);
    }
    50% {
        opacity: 0;
        transform: translateY(100px);
    }
    90% {
        opacity: 1;
        transform: translateY(0);
    }
    100% {
        opacity: 1;
        transform: translateY(0);
    }
}
