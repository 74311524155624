.footer-nav {
    display: block;
    border-top: solid 1px $color-white;
    margin-top: 30px;
    margin-bottom: 30px;
    padding-top: 32px;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            @include breakpoint(large) {
                margin-bottom: 40px;
            }
        }
        a {
            @include rem-typo(16, 24);
            font-family: $f-display;
            color: $color-white;
            letter-spacing: rem(1.5px);
            text-transform: uppercase;
            &:hover,
            &.active {
                color: $color-ocean-green;
            }
            @include breakpoint(large) {
                @include rem-typo(18, 26);
            }
        }
    }
    @include breakpoint(large) {
        border-top: 0;
        margin-top: -6px;
        margin-bottom: 44px;
        padding-top: 0;
    }
}
.footer-nav-secondary {
    padding-bottom: 40px;
    border-bottom: solid 1px $color-white;
    margin-bottom: 30px;
    ul {
        margin: 0;
        padding: 0;
        list-style-type: none;
        li {
            margin-bottom: 30px;
            &:last-child {
                margin-bottom: 0;
            }
            @include breakpoint(large) {
                margin-bottom: 25px;
            }
        }
        a {
            @include rem-typo(14, 22);
            font-family: $f-display;
            color: $color-white;
            letter-spacing: rem(2.5px);
            text-transform: uppercase;
            &:hover,
            &.active {
                color: $color-ocean-green;
            }
        }
    }
    @include breakpoint(large) {
        padding-bottom: 0;
        border-bottom: 0;
        margin-bottom: 0;
    }
}
.footer-nav-items {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
}
.footer-nav-link {
    @include rem-typo(18, 21);
    font-weight: 300;
    letter-spacing: 0.1em;
    color: $c-body-text;
}
.footer-nav-tertiary {
    line-height: 1;
    margin-top: 10px;
    ul {
        margin: 0;
        padding: 0;
        display: inline-flex;
        flex-flow: row wrap;
        list-style: none;
    }
    li {
        padding-right: 8px;
        margin-right: 8px;
        border-right: solid 1px $color-white;
        line-height: 1;
        &:last-child {
            margin-right: 0;
            border-right: 0;
        }
    }
    a {
        @include rem-typo(13, 16);
        font-weight: 500;
        color: $color-white;
        letter-spacing: rem(0.43px);
        &:hover,
        &.active {
            color: $color-ocean-green;
        }
    }
    @include breakpoint(large) {
        margin-top: 0;
    }
}
