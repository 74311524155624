// CALENDAR
.calendar-container {
    position: relative;
    min-height: 400px;
    margin-top: rem(24px);
    margin-bottom: rem(24px);
    @include breakpoint(350px down) {
        margin-left: rem(-20px);
        margin-right: rem(-20px);
    }
    @include breakpoint(medium) {
        margin-top: 0;
        margin-bottom: 0;
    }
}
.calendar {
    background-color: $color-ocean-green;
    padding: rem(24px) rem(24px) rem(30px);
    @include breakpoint(350px down) {
        padding: rem(15px) rem(15px) rem(24px);
    }
    @include breakpoint(medium) {
        margin-top: rem(-44px);
        width: 100%;
    }
    .asd__wrapper { // sass-lint:disable-line class-name-format
        border: 0;
        background: $color-ocean-green;
    }
}
.month-toggle {
    background: $color-ocean-green;
}
.month-toggle-input {
    position: absolute;
    opacity: 0;
    & + .month-toggle-label {
        position: relative;
        cursor: pointer;
        padding: 0;
        &::before {
            content: "";
            width: rem(22px);
            height: rem(22px);
            border: solid 1px $color-black;
            margin-right: rem(10px);
            display: inline-block;
            vertical-align: middle;
        }
    }
    &:focus + .month-toggle-label {
        &::before {
            box-shadow: 0 0 0 3px rgba(0, 0, 0, 0.12);
        }
    }
    &:hover + .month-toggle-label {
        color: $color-white;
        &::before {
            border: solid 1px $color-white;
        }
    }
    &:checked + .month-toggle-label {
        &::after {
            content: "";
            position: absolute;
            left: rem(6px);
            top: rem(6px);
            width: rem(10px);
            height: rem(10px);
            background: url("../svg/originals/calendar-x.svg") top left no-repeat;
        }
    }
}
.month-toggle-label {
    @include rem-typo(12, 18);
    font-family: $f-display;
    font-weight: 800;
    letter-spacing: rem(2px);
    text-transform: uppercase;
}
