.event-filters-container {
    position: relative;
}
.event-filters-wrapper {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: $color-masala;
    margin-top: rem(44px);
    flex-direction: column;
    width: 100%;
    @include breakpoint(medium) {
        margin-top: 0;
        flex-flow: row nowrap;
    }
}
.filter-input-container {
    position: relative;
    display: flex;
    align-items: center;
    @include breakpoint(medium) {
        padding-left: 30px;
    }

    svg {
        fill: $color-white;
    }

    &.event-filter--keywords {
        width: 100%;
        .event-filter-inner {
            border-bottom: 1px solid transparentize($color-white, 0.8);
            @include breakpoint(medium) {
                border-bottom: 0;
                border-right: 1px solid transparentize($color-white, 0.8);
            }
        }
    }
    &.event-filter--category {
        width: 100%;
        @include breakpoint(medium) {
            max-width: 300px;
        }
        .event-filter-inner {
            position: relative;
            &::after {
                content: "";
                display: block;
                position: absolute;
                top: 50%;
                right: 18px;
                border-left: 5px solid transparent;
                border-right: 5px solid transparent;
                border-top: 5px solid $color-white;
                transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
            }
            &.open {
                &::after {
                    border-left: 5px solid transparent;
                    border-right: 5px solid transparent;
                    border-bottom: 5px solid $color-white;
                    border-top: 0;
                }
            }
            &.disabled-filters {
                color: transparentize($color-white, 0.3);

                &::after {
                    border: 0;
                }
            }
        }
    }
    &:last-child {
        .event-filter-inner {
            border-bottom: 0;
            border-right: 0;
        }
    }
    .disabled-filters {
        cursor: not-allowed;
        .text {
            padding-left: rem(40px);
        }
    }
}
.event-filter-inner {
    position: relative;
    margin: 0 rem(16px);
    padding: rem(16px) 0;
    width: 100%;
    @include breakpoint(medium) {
        padding: 0;
        margin: rem(20px) 0;
    }
}
.event-filter-input {
    width: 100%;
}
.event-filter-input input[type="text"],
.event-filter-date,
.event-filter-placeholder {
    @include rem-typo(16, 19);
    letter-spacing: rem(1.24px);
    border: 0;
    background: none;
    box-shadow: none;
    color: $color-white;
    margin-bottom: 0;
    padding: 0 0 0 rem(40px);
    &::placeholder {
        color: $color-white;
    }
}
.event-filter-date,
.event-filter-placeholder,
.disabled-filters,
.active-event-filter {
    @include rem-typo(14, 20);
    font-family: $f-display;
    font-weight: 800;
    letter-spacing: rem(1.4px);
    text-transform: uppercase;
}

.search-icon-wrapper,
.filter-icon-wrapper {
    position: absolute;
    left: 0;
    top: 50%;
    display: flex;
    transform: translateY(-50%);
    pointer-events: none;
}
.multiple-filter-container {
    cursor: pointer;
}
.active-event-filter {
    padding-left: rem(40px);
    padding-right: rem(52px);
    cursor: pointer;
}
.event-filter-dropdown {
    @include rem-typo(14, 20);
    padding: 0 rem(12px) rem(12px);
    font-family: $f-display;
    font-weight: 800;
    letter-spacing: rem(1.4px);
    text-transform: uppercase;
    background-color: $color-masala;
    position: absolute;
    right: 0;
    width: 100%;
    z-index: 3;
    @include breakpoint(medium) {
        max-width: 300px;
    }

    label {
        @include rem-typo(16);
        cursor: pointer;
        margin: 0;
        color: $color-white;
        width: 100%;
        padding: rem(8px) rem(12px);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        position: relative;
        &:hover {
            color: $color-ocean-green;
        }
        &.selected {
            background-color: $color-ocean-green;
            &:hover {
                color: $color-masala;
                .close-icon {
                    stroke: $color-masala;
                }
            }
        }
        .close-icon {
            position: absolute;
            right: 24px;
            top: 50%;
            transform: translateY(-60%);
            padding: 0;
            width: 20px;
            stroke: $color-white;
        }
    }
}

.active-search-filter {
    min-width: rem(215px);
    padding: rem(12px) rem(16px) ;
    margin-left: rem(40px);
    background-color: $color-ocean-green;
    @include breakpoint(medium) {
        top: rem(-4px);
    }

    .close-icon {
        cursor: pointer;
        stroke: $color-white;
        margin-left: rem(14px);
    }
}
