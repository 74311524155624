.nav {
    display: none;
    &.is-active {
        position: absolute;
        display: block;
        left: rem(-20px);
        right: rem(-20px);
        top: 100%;
        width: calc(100% + 40px);
        z-index: 100;
        background: $color-black;
        padding: rem(20px) rem(10px);
        height: calc(100vh - 70px);
        overflow-y: scroll;
        a {
            &:focus {
                color: $color-white;
                &.ancestor {
                    color: $color-ocean-green;
                }
                &:hover {
                    color: $color-ocean-green;
                }
            }
        }
        .primary-nav {
            border-bottom: solid 1px $color-alto;
            margin: 0 rem(20px) rem(30px);
        }
        .primary-nav-items {
            flex-flow: row wrap;
            width: 100%;
        }
        .primary-nav-item {
            width: 100%;
            margin: 0 0 rem(30px);
        }
        .primary-nav-link {
            padding: 0;
        }
        .secondary-nav {
            position: relative;
            top: auto;
            right: auto;
            justify-content: flex-start;
            margin: 0 rem(20px) rem(30px);
        }
        .secondary-nav-items {
            flex-flow: row wrap;
            width: 100%;
        }
        .secondary-nav-item {
            width: 100%;
            margin: 0 0 rem(20px);
        }
        .secondary-nav-link {
            padding: 0;
        }
        .submenu-toggle {
            width: 100%;
        }
        .submenu {
            position: relative;
            top: auto;
            right: auto;
            width: 100%;
            background: none;
            padding: rem(4px) 0 0;
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    @include breakpoint(1180px) {
        display: block;
    }
}
