.sticky-bottom {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 2;
    border-left: 6px solid $color-shadow;
    background-color: $color-masala;
    transition: all 0.5s ease;
    transform: translateY(calc(100% - 50px));
    &.is-open {
        transform: translateY(0);
        .icon-chevron-down {
            transform: rotate(180deg);
        }
    }
    @include breakpoint(large) {
        border-width: 12px;
    }
    .sticky-bottom-btn {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: stretch;
        border-bottom: 1px solid $color-black;
        .btn-text {
            padding: 0 24px;
            text-transform: uppercase;
            font-family: $font-neue;
            @include rem-typo(18px);
            display: flex;
            align-items: center;
            color: $color-white;
            .icon-offer-tickets-wrapper {
                display: inline-flex;
                margin-right: 12px;
                @include breakpoint(large) {
                    margin-right: 26px;
                }
            }
        }
        svg {
            fill: $color-white;
        }
        .icon-chevron-down-wrapper {
            background-color: $color-shadow;
            height: 50px;
            width: 50px;
            display: inline-flex;
            justify-content: center;
            align-items: center;
            .icon-chevron-down {
                transition: all 0.5s ease;
            }
        }
    }
    .offer-container {
        padding: 20px 4px;
        color: $color-white;
        @include breakpoint(large) {
            padding: 24px 0 20px;
        }
        .offer-content {
            padding: 0 20px;
            @include breakpoint(large) {
                display: flex;
                justify-content: space-between;
            }
        }
        .offer-name {
            text-transform: uppercase;
            font-family: $font-neue;
            @include rem-typo(28px, 28px);
            margin-bottom: 12px;
            @include breakpoint(large) {
                @include rem-typo(40px, 40px);
            }
        }
        .cta-container {
            text-align: center;
            display: flex;
            flex-direction: column;
            align-items: stretch;
            margin-top: 24px;
            @include breakpoint(medium) {
                align-items: center;
                margin-top: 0;
            }
        }
        .cta-link {
            background-color: $color-shadow;
            color: $color-white;
            text-transform: uppercase;
            font-family: $font-neue;
            @include rem-typo(14px, 14px);
            padding: 14px 24px;
            @include breakpoint(large) {
                @include rem-typo(18px, 18px);
                padding: 16px 24px;
            }
        }
        small {
            font-style: italic;
            @include rem-typo(10px, 24px);
            @include breakpoint(large) {
                @include rem-typo(12px, 24px);
                margin-top: 8px;
            }
        }
    }
}
