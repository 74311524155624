.block-content-page,
.single-chronicle {
    .block-container {
        margin-top: rem(32px);
        &:first-child {
            margin-top: 0;
        }
        &:last-child {
            margin-bottom: rem(32px);
        }
        @include breakpoint(medium) {
            margin-top: rem(36px);
            &:first-child {
                margin-top: 0;
            }
            &:last-child {
                margin-bottom: rem(72px);
            }
        }
    }
    @include breakpoint(medium) {
        @include rem-typo(18px);
    }
}
.title-block {
    h2 {
        margin-bottom: 0;
    }
}
.link-block {
    @include rem-typo(14);
    letter-spacing: rem(2.5px);
    text-transform: uppercase;
    font-family: $f-display;
    svg {
        margin-bottom: rem(6px);
        margin-left: rem(19px);
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
    &:hover {
        svg {
            transform: translateX(35%);
        }
    }
    .btn {
        &.btn--in-aside {
            background-color: $color-ocean-green;
            color: $color-white;
        }
    }
}
.richtext-image {
    &.full-width {
        width: 100%;
    }
    &.left {
        float: left;
        margin-top: rem(9px);
        margin-right: rem(40px);
        margin-bottom: rem(22px);
    }
    &.right {
        float: right;
        margin-top: rem(9px);
        margin-left: rem(40px);
        margin-bottom: rem(22px);
    }
}
.rich-text {
    a {
        text-decoration: underline;
        &:hover {
            color: $color-ocean-green;
            text-decoration: none;
        }
    }
    .responsive-object {
        width: 100%;
        position: relative;
        margin: rem(28px) 0;
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
}
.video-block {
    position: relative;
    .responsive-object {
        position: relative;
        iframe {
            position: absolute;
            width: 100%;
            height: 100%;
        }
    }
    video {
        width: 100%;
        height: 100%;
    }
    .video {
        position: relative;
        top: auto;
        left: auto;
        right: auto;
        bottom: auto;
    }
}
.quote-block {
    background-color: $color-masala;
    color: $color-white;
    padding: rem(44px) 0;
    margin-bottom: rem(60px);
    @include breakpoint(medium) {
        padding: rem(88px) 0;
        margin-bottom: rem(88px);
    }
    .quote {
        @include rem-typo(18px);
        font-family: $f-titles;
        font-weight: bold;
        line-height: rem(22px);
        letter-spacing: rem(1.8px);
        padding-bottom: rem(30px);
        @include breakpoint(medium) {
            @include rem-typo(26px);
            line-height: rem(28px);
            letter-spacing: rem(2px);
            padding-bottom: rem(44px);
        }
    }
    .author {
        @include rem-typo(20px);
        font-family: $f-display;
        text-transform: uppercase;
        letter-spacing: rem(0.67px);
        margin-bottom: rem(15px);
        @include breakpoint(medium) {
            @include rem-typo(32px);
            letter-spacing: rem(1.07px);
        }
    }
}
.block-table {
    overflow: auto;
    table {
        min-width: 600px;
        margin-bottom: 0;
    }
    thead {
        tr {
            border-top: 0;
        }
    }
    th {
        padding: rem(8px);
    }
    td {
        padding: rem(8px);
        min-width: 100px;
    }
}
.block-show-slider {
    margin-bottom: -32px;
    @include breakpoint(medium) {
        margin-bottom: -72px;
    }
}
