.series-list {
    margin-bottom: 64px;
}
.serie-card {
    display: block;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100%;
    height: 0;
    padding-bottom: 64%;
    position: relative;
    &:hover,
    &:focus {
        svg {
            fill: $color-white;
        }
    }
}
