.venue-header {
    padding-bottom: 90px;
    @include breakpoint(medium) {
        padding-bottom: 140px;
    }
}
.venue-wrapper {
    margin-top: -140px;
}
.venue-image-container {
    order: 2;
    @include breakpoint(medium) {
        order: 1;
    }
}
.venue-details-container {
    order: 1;
    @include breakpoint(medium) {
        order: 2;
    }
}
.venue-image-wrapper {
    margin-bottom: 30px;
    @include breakpoint(medium) {
        margin-bottom: 88px;
    }
}
.venue-image {
    margin-left: -10px;
    margin-right: -10px;
    img {
        width: 100%;
    }
    @include breakpoint(medium) {
        margin-left: calc((1/12 * 100% * -1));
        margin-right: 0;
        width: calc(100% + (1/12 * 100%));
    }
}
.venue-image-caption {
    @include rem-typo(14, 20);
    margin-top: 8px;
}
.venue-details {
    background: $color-masala;
    color: $color-white;
    padding: 30px 10px;
    margin-left: -10px;
    margin-right: -10px;
    margin-bottom: 60px;
    dl {
        margin-bottom: 0;
        & + dl {
            margin-top: 44px;
        }
    }
    dt {
        @include rem-typo(14, 20);
        font-family: $f-display;
        font-weight: 800;
        text-transform: uppercase;
        letter-spacing: rem(1px);
        color: $color-white;
    }
    dd {
        @include rem-typo(18, 22);
        font-family: $f-default;
        font-weight: 500;
        letter-spacing: rem(0.5px);
        color: $color-white;
    }
    @include breakpoint(medium) {
        margin-bottom: 20px;
        margin: 20px 0 20px -120px;
        padding: 44px 30px 44px 120px;
        background: linear-gradient(85deg, transparent 13%, $color-masala calc(13% + 1px));
    }
    @include breakpoint(large) {
        padding: 70px 30px 70px 100px;
        margin-top: 100px;
    }
}
