.block-slider-wrapper {
    position: relative;
    margin: 30px 0 0;
}
.block-slider {
    position: relative;
    .slick-track {
        display: flex;
    }
}
.block-slider-prev,
.block-slider-next {
    display: flex;
    position: absolute;
    top: 50%;
    margin-top: -26px;
    cursor: pointer;
    background: $color-black;
    width: 54px;
    height: 54px;
    align-items: center;
    justify-content: center;
    z-index: 1;
    .icon-wrapper {
        display: flex;
    }
    svg {
        fill: $color-white;
    }
}
.block-slider-prev {
    left: 0;
}
.block-slider-next {
    right: 0;
}
.block-slider-pager {
    cursor: pointer;
    svg {
        fill: $color-dusty-gray;
    }
    &:hover,
    .slick-active & {
        svg {
            fill: $color-black;
        }
    }
}
.block-slider-slide {
    display: flex;
    align-items: center;
    img {
        margin: 0 auto;
    }
    .block-slider-large & {
        img {
            width: 100%;
        }
    }
    .block-slider-vertical & {
        .poster-image-container {
            padding-bottom: calc(1640/1100 * 100%);
        }
    }
    .poster-image-container {
        background: $color-black;
        padding-bottom: calc(1080/1920 * 100%);
    }
}
.block-slider-captions {
    padding: 16px 0;
}
.block-slider-caption {
    @include rem-typo(16, 20);
    color: $color-masala;
    & + .block-slider-credits {
        margin-top: 10px;
    }
}
.block-slider-credits {
    @include rem-typo(14, 20);
    color: $color-masala;
}
.block-slider-pagination {
    padding: 16px 0;
    .slick-dots {
        justify-content: center;
        line-height: 1;
        li {
            &:only-child {
                display: none;
            }
        }
    }
}
