.block-folder {
    animation: flipdown 0.5s ease both;
    animation-delay: 1s;
    border-top: solid 1px $color-grey-mineshaft;
    border-bottom: solid 1px $color-grey-mineshaft;
    & + .block-folder {
        border-top: 0;
    }
}
.block-folder-title {
    @include rem-typo(18, 27);
    font-weight: bold;
    font-family: $f-default;
    letter-spacing: rem(1.5px);
    padding: 21px 0;
    margin-bottom: 0;
}
.block-folder-toggle {
    padding-right: rem(30px);
    line-height: inherit;
    outline: none;
    width: 100%;
    background: 0;
    text-align: left;
    cursor: pointer;
    &::after {
        content: "+";
        display: block;
        position: absolute;
        top: 24px;
        right: 0;
        .block-folder--open & {
            content: "-";
        }
    }
}
.block-folder-content {
    transition: all 0.25s ease-in-out;
    position: relative;
    overflow: hidden;
    z-index: 2;
    max-height: 0;
    opacity: 0;
    transform: translate(0, 50%);
    .block-folder--open & {
        max-height: 20000px;
        opacity: 1;
        transform: translate(0, 0);
    }
    .rich-text {
        margin-bottom: rem(32px);
    }
}
