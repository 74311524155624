.event-h-card-desktop {
    padding-bottom: rem(44px);
    &.event-h-card {
        color: $color-black;
        margin-bottom: rem(24px);
        position: relative;
        &.notavailable {
            cursor: not-allowed;
        }
        &:hover {
            color: $color-black;
            .animate {
                svg.available {
                    transform: translateX(35%);
                }
            }
        }
        &:active,
        &:focus,
        &:visited {
            color: $color-black;
        }
    }
    .no-hover-link {
        &:hover {
            color: $color-black;
        }
    }
    .event-h-card-category {
        @include rem-typo(18);
        font-family: $f-titles;
    }
    .poster-image-container {
        padding-bottom: 33.08%;
    }
    .flag-container {
        position: absolute;
        top: 0;
        z-index: 1;
        width: 100%;
    }
    .flag-boxing-day,
    .flag-black-friday {
        @include rem-typo(13);
        padding: rem(6px) rem(10px);
    }
    .event-h-card-info {
        padding-left: rem(43px);
        padding-right: rem(24px);
        padding-bottom: rem(44px);
        position: relative;
    }
    .event-h-card-meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        padding-bottom: rem(24px);
    }
    .event-h-card-title {
        @include rem-typo(24);
        font-family: $f-display;
    }
    .event-h-card-date {
        @include rem-typo(20);
        text-transform: lowercase;
        font-weight: bold;
        padding-bottom: rem(12px);
    }
    .event-h-svg-container {
        fill: $color-ocean-green;
        position: absolute;
        bottom: 24px;
        svg {
            transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
        }
    }
    .event-h-card-price {
        @include rem-typo(16);
        padding-bottom: rem(21px);
    }
    .buy-link-container {
        text-align: center;
        width: 100%;
        max-width: 200px;
        flex-shrink: 0;
    }
    .buy-link-additional,
    .buy-link-postponed {
        padding: rem(12px) 0;
        @include breakpoint(large) {
            @include rem-typo(15);
        }
        @include breakpoint(1200px) {
            @include rem-typo(18);
        }
    }
    .buy-available-link,
    .buy-full-link,
    .buy-cancelled-link {
        padding: rem(22px) 0;
        width: 100%;
    }
}
