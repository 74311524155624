.chronicle-listing {
    .page-header {
        padding-bottom: rem(44px);
        margin-bottom: 0;
        @include breakpoint(1200px) {
            padding-bottom: rem(234px);
        }
    }
    .chronicle-section {
        margin-top: rem(36px);
        ul {
            padding: 0;
        }
        @include breakpoint(1200px) {
            margin-top: rem(-143px);
        }
    }
}
.chronicle-section {
    ul {
        list-style-type: none;
    }
}
.chronicle-featured-section {
    position: relative;
    margin-top: rem(44px);
    @include breakpoint(medium) {
        margin-top: rem(88px);
    }
    .chronicle-title {
        @include rem-typo(38, 40);
        letter-spacing: rem(2px);
    }
}
.chronicle-featured-container {
    background-image: $color-masala;
    margin: auto;
    width: 100%;
    @include breakpoint(1200px) {
        width: 90%;
        margin: 0;
    }
    &:hover,
    &:focus,
    &:active {
        color: $color-white;
        .chronicle-arrow {
            svg {
                transform: translateX(35%);
            }
        }
    }
}
.chronicle-featured-info-wrapper {
    width: 100%;
    background: $color-masala;
    @include breakpoint(medium) {
        background-image: linear-gradient(82deg, $color-black 81px, $color-masala calc(81px + 1px));
    }
    @include breakpoint(1200px) {
        min-height: rem(534px);
    }
}
.chronicle-featured-info {
    width: 80%;
    margin: auto;
    padding: rem(44px) 0;
    .rich-text {
        max-width: 500px;
        @include breakpoint(medium) {
            margin-bottom: rem(30px);
        }
    }
    @include breakpoint(medium) {
        width: 60%;
        padding: rem(58px) 0 rem(108px) rem(58px);
    }
    @include breakpoint(1200px) {
        width: 50%;
        margin: 0;
        padding: rem(58px) 0 rem(58px) rem(128px);
    }
}
.chronicle-featured-img {
    width: 100%;
    img {
        width: 100%;
    }
    @include breakpoint(medium) {
        margin: auto;
        transform: translate(rem(40px), (-60px));
        width: 628px;
    }
    @include breakpoint(1200px) {
        position: absolute;
        margin: 0;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
    }
}
.chronicle-container {
    position: relative;
    margin-bottom: rem(88px);
    .chronicle-arrow {
        position: absolute;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
    }
}
.chronicle-item {
    &:hover {
        color: $color-black;
        svg {
            transform: translateX(35%);
        }
    }
    &:focus,
    &:active {
        color: $color-black;
    }
}
.chronicle-image {
    flex-grow: 1;
    img {
        width: 100%;
    }
}
.chronicle-info {
    flex-grow: 1;
    padding: rem(40px) rem(18px) 0;
}
.chronicle-date {
    font-weight: 600;
    letter-spacing: rem(1px);
    padding-bottom: rem(20px);
    @include breakpoint(medium) {
        @include rem-typo(18px);
        padding-bottom: rem(30px);
        letter-spacing: rem(1.5px);
    }
}
.chronicle-title {
    @include rem-typo(18px);
    font-family: $f-display;
    letter-spacing: rem(1.5px);
    text-transform: uppercase;
    margin: 0 auto;
    padding-bottom: rem(20px);
    @include breakpoint(medium) {
        @include rem-typo(24px);
        letter-spacing: rem(2px);
        padding-bottom: rem(40px);
    }
}
.chronicle-author {
    font-family: $f-titles;
    font-weight: 700;
    letter-spacing: rem(1.5px);
    margin-bottom: 0;
    padding-bottom: rem(20px);
    @include breakpoint(medium) {
        @include rem-typo(18px);
        letter-spacing: rem(2px);
        padding-bottom: rem(30px);
    }
}
.chronicle-author-date {
    @include rem-typo(16, 24);
    font-family: $f-display;
    letter-spacing: rem(1.4px);
    text-transform: uppercase;
}
.chronicle-info,
.chronicle-featured-info {
    .rich-text {
        letter-spacing: rem(1px);
        margin-bottom: rem(44px);

        @include breakpoint(medium) {
            @include rem-typo(18px);
            letter-spacing: rem(1.5px);
        }
    }
}
.chronicle-arrow {
    svg {
        cursor: pointer;
        fill: $color-ocean-green;
        transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    }
}
