.page-header {
    padding: rem(16px) 0 rem(32px);
    margin-bottom: rem(44px);
    background-color: $color-black;
    color: $color-white;
    @include breakpoint(medium) {
        margin-bottom: rem(88px);
        padding: rem(60px) 0 rem(88px);
    }
}
.page-title {
    @include rem-typo(34, 34);
    letter-spacing: 0;
    font-weight: 700;
    font-family: $f-titles;
    margin-bottom: rem(22px);
    @include breakpoint(medium) {
        @include rem-typo(64, 64);
        margin-bottom: rem(34px);
    }
}
.page-intro {
    .rich-text {
        p {
            @include rem-typo(18, 30);
            letter-spacing: rem(1.5px);
            color: $color-white-rock;
            @include breakpoint(medium) {
                @include rem-typo(22, 36);
            }
        }
    }
    li {
        color: $color-white-rock;
    }
}
.page-content {
    h2 {
        margin-bottom: rem(44px);
    }
}
.spotlight-container {
    margin-bottom: rem(44px);
}
.listing-section {
    background-image: linear-gradient(0deg, $color-black 78%, $c-body-bg 78%);
}
