.featured-slider {
    --accent-color: $color-ocean-green;
    display: none;
    overflow-x: hidden;
    overflow-y: hidden;
    position: relative;
    opacity: 0;
    .slick-track {
        display: flex;
        align-items: flex-start;
    }
    .slick-dots {
        position: absolute;
        top: rem(280px);
        bottom: auto;
        left: 50%;
        width: auto;
        transform: translateX(-50%);
        @include breakpoint(medium) {
            flex-flow: row nowrap;
            position: absolute;
            top: 26px;
            left: 50%;
            width: 50%;
            bottom: auto;
            transform: none;
        }
        @include breakpoint(large) {
            flex-flow: row nowrap;
            position: absolute;
            top: 26px;
            left: 60%;
            width: 40%;
            bottom: auto;
            transform: none;
        }
    }
    &.slick-initialized {
        opacity: 1;
    }
}
.featured-slider-prev,
.featured-slider-next {
    position: absolute;
    top: rem(105px);
    width: rem(40px);
    height: rem(40px);
    cursor: pointer;
    background: $color-black;
    display: flex;
    align-items: center;
    justify-content: center;
    border: solid 1px $color-dusty-gray;
    z-index: 1;
    @include breakpoint(medium) {
        top: 50%;
        margin-top: -26px;
        width: rem(54px);
        height: rem(54px);
    }
    span[role="presentation"] {
        display: flex;
    }
    .icon-wrapper {
        display: flex;
    }
    svg {
        fill: $color-white;
    }
}
.featured-slider-prev {
    left: 0;
    background: rgba($color-black, 0.4);
}
.featured-slider-next {
    right: 0;
    background: rgba($color-black, 0.4);
    @include breakpoint(medium) {
        background: $color-black;
    }
}
.featured-slide.slick-slide {
    display: none;
    [data-state="keydown"] {
        &:focus {
            box-shadow: inset 0 0 0 1px $color-white, inset 0 0 0 3px $color-black, inset 0 0 0 4px $color-white !important;
        }
    }
}
.featured-slide-inner {
    display: flex;
    flex-flow: row wrap;
    width: 100%;
    @include breakpoint(medium) {
        flex-flow: row nowrap;
    }
}
.featured-slide-image {
    width: 100%;
    z-index: 1;
    &::after {
        content: "";
        width: 95%;
        margin: auto;
        height: 5px;
        display: block;
        background-color: $color-ocean-green;
        background-color: var(--accent-color);
    }
    @include breakpoint(large) {
        padding-bottom: rem(10px);
        &::after {
            display: none;
        }
    }
    .poster-image-container {
        box-shadow: none;
        padding-bottom: calc(910/1400 * 100%);
        position: relative;
        img {
            width: auto;
            max-height: none;
        }
        @include breakpoint(medium) {
            padding-bottom: 90%;
        }
        @include breakpoint(large) {
            padding-bottom: 65%;
            box-shadow: 10px 10px 0 0 $color-ocean-green;
            box-shadow: 10px 10px 0 0 var(--accent-color);
        }
    }
    @include breakpoint(medium) {
        width: 50%;
    }
    @include breakpoint(large) {
        width: 60%;
    }
}
.featured-slide-content {
    display: flex;
    align-items: center;
    flex-flow: column nowrap;
    justify-content: center;
    padding: rem(48px) rem(20px) 0;
    width: 100%;
    text-align: center;
    max-width: 100%;
    color: $color-white;
    p {
        max-width: 100%;
    }
    @include breakpoint(medium) {
        padding: rem(40px) rem(60px) 0;
        width: 50%;
    }
    @include breakpoint(large) {
        width: 40%;
    }
}
.featured-slide-tagline {
    @include rem-typo(16, 22);
    font-family: $f-titles;
    font-weight: bold;
    color: $color-white-rock;
    letter-spacing: rem(2px);
    margin-bottom: rem(9px);
    @include breakpoint(medium) {
        @include rem-typo(18, 24);
        margin-bottom: rem(18px);
    }
    max-width: 100%;
}
.featured-slide-title {
    @include rem-typo(30, 30);
    font-family: $f-display;
    font-weight: 800;
    color: $color-white;
    margin-bottom: rem(24px);
    text-transform: uppercase;
    max-width: 100%;
    @include breakpoint(medium) {
        @include rem-typo(36, 36);
    }
    @include breakpoint(large) {
        @include rem-typo(48, 48);
        margin-bottom: rem(30px);
    }
    @include breakpoint(xlarge) {
        @include rem-typo(64, 64);
    }
    span {
        overflow-wrap: break-word;
        hyphens: auto;
        display: inline-block;
    }
}
.featured-slide-date {
    @include rem-typo(16, 22);
    text-transform: lowercase;
    font-family: $f-default;
    font-weight: bold;
    color: $color-white-rock;
    letter-spacing: rem(1.5px);
    margin-bottom: rem(20px);
    @include breakpoint(medium) {
        @include rem-typo(18, 24);
        margin-bottom: rem(40px);
    }
}
.featured-slide-actions {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    justify-content: space-between;
    @include breakpoint(460px to medium) {
        flex-flow: row nowrap;
    }
    @include breakpoint(medium) {
        flex-flow: column nowrap;
    }
    a {
        margin-left: 0;
        margin-right: 0;
        @include breakpoint(460px to medium) {
            margin-left: rem(16px);
            margin-right: rem(16px);
            &:first-child {
                margin-left: 0;
            }
            &:last-child {
                margin-right: 0;
            }
        }
        @include breakpoint(medium) {
            margin-left: 0;
            margin-right: 0;
        }
    }
}
.featured-slide-btn {
    background: $color-ocean-green;
    background: var(--accent-color);
    cursor: pointer;
    color: $color-white;
    padding: rem(16px) rem(10px);
    letter-spacing: rem(2.5px);
    transition: all 0.3s cubic-bezier(0.175, 0.885, 0.32, 1.275);
    margin-bottom: rem(32px);
    @include breakpoint(medium) {
        padding: rem(20px) rem(32px);
    }
    &.no-ticket {
        cursor: initial;
        &:hover {
            transform: none;
        }
    }
    &:hover {
        color: $color-white;
        transform: scale(1.1);
    }
    &:active,
    &:focus,
    &:visited {
        color: $color-white;
    }
}
.featured-slide-link {
    @include rem-typo(14, 20);
    position: relative;
    text-transform: uppercase;
    color: $color-white;
    display: inline-block;
    font-weight: 700;
    letter-spacing: rem(2.5px);
    margin-bottom: rem(32px);
    &::before {
        content: "";
        position: absolute;
        bottom: -13px;
        width: rem(60px);
        height: 1px;
        background: $color-ocean-green;
        background: var(--accent-color);
        left: 50%;
        margin-left: -30px;
    }
    &:hover {
        transition: all 300ms;
        color: $color-ocean-green;
        color: var(--accent-color);
    }
}
.featured-slider-pager {
    cursor: pointer;
    svg {
        fill: $color-white;
        opacity: 0.25;
    }
    &:hover,
    .slick-active & {
        svg {
            fill: $color-white;
            opacity: 1;
        }
    }
}
.featured-slide-video {
    position: relative;
    padding-bottom: calc(533/807 * 100%);
    height: 0;
    overflow: hidden;
    max-width: 100%;
    iframe,
    object,
    embed {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }
}
