.experience {
    position: relative;
    margin-bottom: 120px;
    @include breakpoint(large) {
        margin-bottom: 185px;
    }
}
.experience-link {
    @include rem-typo(24, 24);
    font-family: $f-display;
    font-weight: 800;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    position: absolute;
    bottom: 0;
    right: -20px;
    transform: translateY(50%);
    padding: 30px;
    background: $color-masala;
    color: $color-white;
    max-width: 95%;
    .text {
        margin-top: -6px;
        @include breakpoint(medium) {
            margin-top: -14px;
        }
    }
    .icon-wrapper {
        display: flex;
        align-items: center;
        flex-shrink: 0;
        margin-left: 40px;
        width: 29px;
        @include breakpoint(medium) {
            margin-left: 80px;
            width: auto;
        }
    }
    svg {
        fill: $color-ocean-green;
    }
    &:hover {
        transition: all 300ms;
        color: $color-ocean-green;
    }
    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: -20px;
        display: block;
        background-image: linear-gradient(85deg, transparent 50%, $color-masala calc(50% + 1px));
        width: 20px;
        height: 100%;
    }
    @include breakpoint(medium) {
        @include rem-typo(36, 38);
        padding: 60px 30px 56px 80px;
    }
    @include breakpoint(large) {
        @include rem-typo(40, 43);
        right: -60px;
    }
}
